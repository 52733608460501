import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { Profile, ProfileActions, ProfileSelectors } from '@app/core';
import { filterTruthy } from '@app/utils';

@Injectable()
export class ProfileResolver implements Resolve<Profile | boolean> {
  constructor(
    private profileActions: ProfileActions,
    private profileSelectors: ProfileSelectors,
  ) {}

  resolve() {
    this.profileActions.getProfile();
    return <Observable<Profile | boolean>>this.profileSelectors.profile.pipe(
      filterTruthy(),
      take(1),
      catchError(() => of(false)),
    );
  }
}
