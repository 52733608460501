import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';
import { of } from 'rxjs';
import { catchError, tap, withLatestFrom } from 'rxjs/operators';

import { snakeCase } from '@app/utils';

import { PatientSelectors } from '../../patient';
import { TrackEvent, trackEvent } from './analytics.actions';

@Injectable()
export class AnalyticsEffects {
  constructor(
    private action$: Actions,
    private angulartics2MixPanel: Angulartics2Mixpanel,
    private patientSelectors: PatientSelectors,
  ) {}

  mixpanelActionTracking$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(trackEvent),
        withLatestFrom(this.patientSelectors.patientId),
        tap(([action, patientId]: [TrackEvent, number]) =>
          this.angulartics2MixPanel.eventTrack(
            action.payload.action,
            snakeCase({
              patientId,
              ...action.payload.properties,
            }),
          ),
        ),
        catchError(error => of(error)),
      ),
    { dispatch: false },
  );
}
