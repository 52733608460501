import {
  AbstractControl,
  FormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import {
  VitalsByType,
  vitalTypes,
} from '@app/modules/vitals-data/shared/vitals-data.type';

import {
  MeasurementValidationRules,
  VitalsValidationRanges,
} from './measurement-validation-rules.type';

/* Convert lbs/inches^2 to kg/m^2. */
const conversionFactor = 703;

export const getBmi = (weight: number, height: number) =>
  (weight / (height * height)) * conversionFactor;

export const convertAgeToHalfMonths = (ageInMonths: number) =>
  Math.floor(ageInMonths) + 0.5;

export const convertAgeToHalfYearInMonths = (ageInMonths: number) =>
  (ageInMonths * 2.0) / 12 / 2;

export const getMinByType = (
  type: string,
  validationRules: MeasurementValidationRules,
  useVitalsValidationRanges: boolean,
  validationRanges?: VitalsValidationRanges,
) => {
  let min;
  if (useVitalsValidationRanges) {
    min =
      validationRanges[type].lowWarningMin ||
      validationRanges[type].expectedMin;
  } else {
    min = validationRules[type] ? validationRules[type].min : 0;
  }
  return min;
};

export const getMaxByType = (
  type: string,
  validationRules,
  useVitalsValidationRanges: boolean,
  validationRanges?: VitalsValidationRanges,
) => {
  let max;
  if (useVitalsValidationRanges) {
    max =
      validationRanges[type].highWarningMax ||
      validationRanges[type].expectedMax;
  } else {
    switch (type) {
      case vitalTypes.weight:
        max = 999;
        break;
      case vitalTypes.height:
        max = 108;
        break;
      case vitalTypes.pulseOximetry:
        max = 100;
        break;
      case vitalTypes.oxygenConcentration:
        max = 100;
        break;
      default:
        // diastolicPressure, systolicPressure, heartRate, respiratoryRate, & temperature
        max = validationRules[type].max;
    }
  }
  return max;
};

export const diastolicConditionallyRequiredValidator = (
  formGroup: UntypedFormGroup,
) => {
  if (formGroup.value.systolicPressure) {
    return Validators.required(formGroup.get('diastolicPressure'))
      ? {
          diastolicPressureConditionallyRequired: true,
        }
      : null;
  }
  return null;
};

export const systolicConditionallyRequiredValidator = (
  formGroup: UntypedFormGroup,
) => {
  if (formGroup.value.diastolicPressure) {
    return Validators.required(formGroup.get('systolicPressure'))
      ? {
          systolicPressureConditionallyRequired: true,
        }
      : null;
  }
  return null;
};

const isInLowWarningRange = (
  type: string,
  value: number,
  validationRanges: VitalsValidationRanges,
) => {
  if (
    !validationRanges[type].lowWarningMin ||
    !validationRanges[type].lowWarningMax
  ) {
    return false;
  }

  return (
    value >= validationRanges[type].lowWarningMin &&
    value <= validationRanges[type].lowWarningMax
  );
};

const isInHighWarningRange = (
  type: string,
  value: number,
  validationRanges: VitalsValidationRanges,
) => {
  if (
    !validationRanges[type].highWarningMin ||
    !validationRanges[type].highWarningMax
  ) {
    return false;
  }

  return (
    value >= validationRanges[type].highWarningMin &&
    value <= validationRanges[type].highWarningMax
  );
};

interface AbstractControlWarn extends AbstractControl {
  warnings: { warningMessage: string };
}

const getWarnings = (
  type: string,
  value: number,
  validationRanges: VitalsValidationRanges,
) => {
  let warnings;
  if (isInLowWarningRange(type, value, validationRanges)) {
    warnings = { warningMessage: 'Below expected value' };
  } else if (isInHighWarningRange(type, value, validationRanges)) {
    warnings = { warningMessage: 'Above expected value' };
  } else {
    warnings = null;
    return null;
  }
  return warnings;
};

export const createWarningsValidatorByType = (
  type: string,
  useVitalsValidationRanges: boolean,
  validationRanges: VitalsValidationRanges,
) => {
  return (control: AbstractControlWarn): ValidationErrors | null => {
    if (!control.value) {
      control.warnings = null;
      return null;
    }
    const value = control.value;

    if (!useVitalsValidationRanges) {
      control.warnings = null;
      return null;
    } else {
      control.warnings = getWarnings(type, value, validationRanges);
    }
    return null;
  };
};
