<label class="padding-normal-horizontal"
       om-layout
       om-layout-gutter
       om-layout-align="start center">
  <span om-flex="20">Task Template</span>
  <omg-auto-complete [clearable]="true"
                     om-flex="75"
                     (change)="updateTodo($event)"
                     [(ngModel)]="todo.taskTemplateId"
                     [items]="taskTemplateTypes"
                     bindLabel="label"
                     bindValue="value"
                     trackByKey="value"
                     data-cy="misc-task-template-select"
                     placeholder="Select Template">
  </omg-auto-complete>
</label>
