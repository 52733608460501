import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { PatientMedicationPrescriptionHistoryItem } from '@app/modules/medications/shared/patient-medications.type';

@Component({
  selector: 'omg-change-rx-medication-item',
  templateUrl: './change-rx-medication-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeRxMedicationItemComponent implements OnInit {
  @Input()
  medication: PatientMedicationPrescriptionHistoryItem;

  isLoading$: Observable<boolean>;
  errors$: Observable<any>;
  optionVisible: boolean;
  noteToPharmacistPlaceholder: string;
  noteToPharmacistCharactersRemaining: string;
  notesToPharmacistPlaceholder: string;

  form: UntypedFormGroup;

  constructor() {}

  ngOnInit() {
    this.setupSelectors();
  }

  private setupSelectors() {
    this.isLoading$ = of(false);
    this.errors$ = of([]);
  }
}
