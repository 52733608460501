<omg-section-title>
  <div om-layout
       om-layout-fill
       om-layout-align="space-between center">
    <span>Allergies</span>
    <span class="om-icon clickable fa fa-trash"
          (click)="deleteFromNote()"
          *ngIf="!signed"
          omgStopEvent="click"
          omgTooltip
          tooltipText="Delete from Note"
          tooltipPosition="left"></span>
  </div>
</omg-section-title>
<ul class="padding-normal"
    om-layout="vertical"
    om-layout-gutter>
  <li *ngIf="noKnownAllergies">No Known Allergies</li>
  <li *ngFor="let patientAllergy of patientAllergies; trackBy: trackByFn">
    <strong>{{ patientAllergy.name || patientAllergy.allergy.name }} </strong>
    <span *ngIf="cehrtViewEnabled$ | ngrxPush; else legacyReactions"
          class="padding-half-horizontal">
      {{ reactionSeverityText(patientAllergy) }}
    </span>
    <ng-template #legacyReactions>
      <span *ngIf="patientAllergy.reaction">({{ patientAllergy.reaction }})</span>
    </ng-template>
  </li>
</ul>
