export enum FeatureFlagNames {
  adminUiTasks = 'admin-ui-tasks',
  alternateBookingFlowIos = 'alternate_booking_flow_ios',
  alternateBookingFlowWeb = 'alternate_booking_flow_web',
  analytics = 'analytics',
  autoCheckInCreation = 'auto_check_in_creation',
  autoDymoLabel = 'auto_dymo_label',
  autoVisitFollowUpOrderCreation = 'auto_visit_follow_up_order_creation',
  billingDisallowDocumentUploads = 'billing-disallow-document-uploads',
  billingEAndMUpdate = 'billing_e_and_m_update',
  billingPerformProceduresDocuments = 'billing_perform_procedures_documents',
  billingPerformProceduresMeasurements = 'billing-perform-procedures-measurements',
  billingCheckOutSelfPay = 'billing-check-out-self-pay',
  billingOpenPracticeZemSegment = 'billing-open-practice-zem-segment',
  billingProcedureInteractions = 'billing-procedure-interactions',
  billingWaystarEligibilityAPI = 'billing-waystar-eligibility-api',
  billingOpenPracticeAdtSync = 'billing-open-practice-adt-sync',
  bhxFollowUpVisitAppointmentTypeAutocomplete = 'follow-up-visit-appointment-type-autocomplete',
  bhxFollowUpVisitIncludesAdminBookedAppointmentTypes = 'follow-up-visit-includes-admin-booked-appointment-types',
  cases = 'cases',
  ccd = 'ccd',
  cehrtMedlinePlusConnectLink = 'cehrt-medline-plus-connect-link',
  chat = 'chat',
  chartPresence = 'chart-presence',
  chartAppSyncCommunication = 'chart-app-sync-communication',
  chartProceduresTab = 'chart-procedures-tab',
  clinicalTimelineHideLabOrderNotes = 'clinical-timeline-hide-lab-order-notes',
  clinicalTimelineShowLabOrders = 'clinical-timeline-show-lab-orders',
  connectedDeviceOrders = 'orders-connected-device-orders',
  consultOrderAutomation = 'consult_order_automation',
  derm = 'derm',
  frontendPlatformJadeTheme = 'frontend-platform-jade-theme',
  genericFollowUpOrders = 'generic_follow_up_orders',
  growthChartsDownSyndrome = 'growth-charts-down-syndrome',
  healthHistory = 'health_history',
  insightsConnectedDeviceAuthToggle = 'insights-connected-device-authorization-toggle',
  interopManualAdtTrigger = 'interop_manual_adt_trigger',
  interopSendDmCcd = 'interop_send_dm_ccd',
  labAutoReporting = 'lab_auto_reporting',
  labOrdersTestMenuLink = 'lab-orders-test-menu-link',
  matchInsuranceIncompleteContact = 'match-insurance-incomplete-contact',
  meds1000CharInstructions = 'meds-1000-char-instructions',
  medsCancelRx = 'meds-cancel-rx',
  medsChangeRx = 'meds-change-rx',
  medsCreateSuspectedProblems = 'meds-create-suspected-problems',
  medsDisplayLabAutoreportabilityRules = 'meds_display_lab_autoreportability_rules',
  medsDisplaySmokingStatus = 'meds-display-smoking-status',
  medsSmokingStatusCehrtRequirements = 'meds-smoking-status-cehrt-requirements',
  medsFamilyHealthHistory = 'meds-family-health-history',
  medsFamilyHealthHistoryAddToNote = 'meds-family-health-history-add-to-note',
  medsImplantableDevices = 'meds-implantable-devices',
  medsRxChangeEditor = 'meds_rx_change_editor',
  medsRxRenewalRequestEditor = 'meds-rx-renewal-request-editor',
  medsRxHistoryFeature = 'meds_rx_history_feature',
  medsSmokingCessationCreateSuspectedProblems = 'meds-smoking-cessation-create-suspected-problems',
  medsAllergiesAndReactionsCehrtRequirements = 'meds-allergies-and-reactions-cehrt-requirements',
  medsVitalsRangeValidation = 'meds-vitals-range-validation',
  memberManagementUi = 'member-management-ui',
  mentalHealthNotePrivacyConsent = 'mental-health-note-privacy-consent',
  mlMatchmaker = 'ml_matchmaker',
  mlMatchmakerProvider = 'ml_matchmaker_provider',
  mlNoteRetitling = 'ml_note_retitling',
  mobileWebEnterpriseReg = 'mobile_web_enterprise_reg',
  new1lifeConsultOrders = 'new_1life_consult_orders',
  new1lifeDualPhlebWorkflow = 'new_1life_dual_phleb_workflow',
  new1lifeEnableDeletingOfficeNote = 'new-1life-enable-deleting-office-note',
  new1lifeMessagingDeletion = 'new_1life_messaging_deletion',
  new1lifeVaccineOrders = 'new_1life_vaccine_orders',
  nonEssentialSidekiqJobs = 'non_essential_sidekiq_jobs',
  noteRedact = 'note-redact',
  onelifeMobileEarlyAccess = 'onelife_mobile_early_access',
  onlineBillPay = 'online_bill_pay',
  ordersTabRedesign = 'orders-tab-redesign',
  patientCheckInModal = 'patient_check_in_modal',
  patientTimeline = 'patient_timeline',
  patientTimelineAndroid = 'patient_timeline_android',
  patientTimelineIos = 'patient_timeline_ios',
  pcpCommentBoxCollapse = 'pcp-comment-box-collapse',
  pcpCommentByline = 'pcp-comment-byline',
  pedsVitalsWeightForLengthPercentiles = 'peds-vitals-weight-for-length-percentiles',
  pedsVitalsWeightForLengthPercentilesDisplay = 'peds-vitals-weight-for-length-percentiles-display',
  programSuggestionsOneClickReferral = 'program-suggestions-one-click-referral',
  providerStats = 'provider_stats',
  registrationEmailOptOut = 'registration_email_opt_out',
  replyToCst = 'reply_to_cst',
  showRealTimeVbcRefreshSignals = 'show-real-time-vbc-refresh-signals',
  sidekiqJobs = 'sidekiq_jobs',
  surescriptsEnabled = 'surescripts_enabled',
  userInfoPopover = 'user-info-popover',
  vaccineAddMultiStepHistory = 'vaccine-add-multi-step-history',
  vaccineOrderDeleteAndEdit = 'vaccine_order_delete_and_edit',
  video = 'video',
  taskChainingActionBar = 'task-chaining-action-bar',
  tasksPreventDuplicateWork = 'tasks-prevent-duplicate-work',
  childHeadCirc = 'under-3-yo-head-circumference',
}

type ValueOf<T> = T[keyof T];
export type FeatureFlag = ValueOf<FeatureFlagNames>;
