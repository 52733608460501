import { Injectable } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';

import { selectPatientState } from '@app/core/patient/store/patient.selectors';
import { SummaryAssociationType } from '@app/features/summaries/shared/summaries-api.type';

import { Todo } from '../shared/todo.type';
import {
  selectAll,
  selectEntities,
  selectTodoState,
  TodoState,
} from './todo.reducer';

export const selectTodoEntities = createSelector(
  selectTodoState,
  selectEntities,
);

export const selectAllTodoEntities = createSelector(selectTodoState, selectAll);

export const selectTodoById = createSelector(
  selectTodoState,
  (state: TodoState, { id }) => state.entities[id],
);

export const selectTodoByEntity = createSelector(
  selectAllTodoEntities,
  (todos, { id, type }): Todo =>
    todos.find(
      (todo: Todo) =>
        todo.associatedWithId === id && todo.associatedWithType === type,
    ),
);

export const selectTodoBySummaryId = createSelector(
  selectAllTodoEntities,
  (todos, { summaryId }): Todo =>
    todos.find(
      e =>
        e.associatedWithId === summaryId &&
        e.associatedWithType === SummaryAssociationType,
    ),
);

export const selectLoading = createSelector(
  selectTodoState,
  state => state.loading,
);

export const selectIsComplete = createSelector(
  selectTodoById,
  todo => todo && todo.state === 'finished',
);

export const selectIsIncomplete = createSelector(
  selectTodoById,
  selectIsComplete,
  (todo, isComplete) => !!todo && !!todo.id && !isComplete,
);

export const selectIsIncompleteAndCosign = createSelector(
  selectTodoById,
  selectIsIncomplete,
  (todo, isIncomplete) => todo && todo.name === 'Co-Sign' && isIncomplete,
);

export const selectTodoError = createSelector(
  selectTodoState,
  state => state.error,
);

export const selectHasTodoError = createSelector(
  selectTodoError,
  error => !!error,
);

export const selectNextTaskTemplates = createSelector(
  selectTodoById,
  todo => todo?.nextTaskTemplates || [],
);

export const selectTaskTemplateIsChangeable = createSelector(
  selectTodoById,
  todo => todo?.taskTemplateIsChangeable,
);

@Injectable()
export class TodoSelectors {
  constructor(private store: Store<TodoState>) {}

  get entities() {
    return this.store.pipe(select(selectTodoEntities));
  }

  get loading() {
    return this.store.pipe(select(selectLoading));
  }

  get error() {
    return this.store.pipe(select(selectTodoError));
  }

  get hasError() {
    return this.store.pipe(select(selectHasTodoError));
  }

  todoById(id: number) {
    return this.store.pipe(select(selectTodoById, { id }));
  }

  todoByEntity(id: number, type: string) {
    return this.store.pipe(select(selectTodoByEntity, { id, type }));
  }

  todoBySummaryId(summaryId: number) {
    return this.store.pipe(select(selectTodoBySummaryId, { summaryId }));
  }

  isComplete(id: number) {
    return this.store.pipe(select(selectIsComplete, { id }));
  }

  isIncomplete(id: number) {
    return this.store.pipe(select(selectIsIncomplete, { id }));
  }

  isIncompleteAndCosign(id: number) {
    return this.store.pipe(select(selectIsIncompleteAndCosign, { id }));
  }

  nextTaskTemplates(id: number) {
    return this.store.pipe(select(selectNextTaskTemplates, { id }));
  }

  taskTemplateIsChangeable(id: number) {
    return this.store.pipe(select(selectTaskTemplateIsChangeable, { id }));
  }
}
