import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  filter,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { CollapseDirective } from '@app/shared/components/collapse';
import { filterTruthy, utcDateToLocalDate } from '@app/utils';

import { HistoricalProcedureCaptureForm } from '../../shared/historical-procedure-capture-form';
import { HistoricalProcedureCaptureFormService } from '../../shared/historical-procedure-capture-form.service';
import { ProcedureCapture } from '../../shared/historical-procedure-capture.type';
import { isProcedureCaptureVerified } from '../../shared/historical-procedure-capture.utils';
import { HistoricalProcedureCaptureActions } from '../../store/historical-procedure-capture.actions';
import { HistoricalProcedureCaptureSelectors } from '../../store/historical-procedure-capture.selectors';

@Component({
  selector: 'omg-historical-procedure-capture',
  templateUrl: './historical-procedure-capture.component.html',
  styleUrls: ['./historical-procedure-capture.component.scss'],
})
export class HistoricalProcedureCaptureComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() documentId: any;
  @Input() attemptedNoteSubmit: boolean;

  @ViewChild('collapseRef', { static: true })
  collapseRef: CollapseDirective;

  form: HistoricalProcedureCaptureForm;
  isCaptureGenerated$: Observable<boolean>;
  isCaptureVerified$: Observable<boolean>;
  isCaptureLoading$: Observable<boolean>;
  isCaptureFormExpanded$: Observable<boolean>;
  verifiedProcedureCapture$: Observable<ProcedureCapture>;

  private unsubscribe$ = new Subject();

  constructor(
    private historicalProcedureCaptureActions: HistoricalProcedureCaptureActions,
    private historicalProcedureCaptureSelectors: HistoricalProcedureCaptureSelectors,
    private historicalProcedureCaptureFormService: HistoricalProcedureCaptureFormService,
  ) {}

  ngOnInit() {
    this.form = this.historicalProcedureCaptureFormService.buildForm();
    this.historicalProcedureCaptureActions.getByDocumentId(this.documentId);
    this.historicalProcedureCaptureActions.expandForm(this.documentId);
    this.setHistoricalProcedureCaptureSelectors();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.attemptedNoteSubmit?.currentValue) {
      this.form.controls.markAllAsTouched();
    }
  }

  /* istanbul ignore next */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    this.listenForUpdateProcedureEvents();
  }

  collapseForm() {
    this.historicalProcedureCaptureActions.collapseForm(this.documentId);
    this.collapseRef.collapse();
  }

  private setHistoricalProcedureCaptureSelectors(): void {
    const isCaptureLoaded$ = this.historicalProcedureCaptureSelectors
      .getLoadingByDocumentId(this.documentId)
      .pipe(
        filter(isLoading => isLoading === false),
        takeUntil(this.unsubscribe$),
      );

    this.isCaptureLoading$ = this.historicalProcedureCaptureSelectors
      .getLoadingByDocumentId(this.documentId)
      .pipe(takeUntil(this.unsubscribe$));

    this.isCaptureGenerated$ = isCaptureLoaded$.pipe(
      switchMap(() =>
        this.historicalProcedureCaptureSelectors.getErrorByDocumentId(
          this.documentId,
        ),
      ),
      map(error => !error),
    );

    this.isCaptureVerified$ = isCaptureLoaded$.pipe(
      switchMap(() =>
        this.historicalProcedureCaptureSelectors.getByDocumentId(
          this.documentId,
        ),
      ),
      filterTruthy(),
      tap(procedureCapture => {
        if (!isProcedureCaptureVerified(procedureCapture)) {
          this.setProcedureCaptureForm(procedureCapture);
        }
      }),
      map(isProcedureCaptureVerified),
    );

    this.isCaptureFormExpanded$ = this.historicalProcedureCaptureSelectors
      .getFormExpandedByDocumentId(this.documentId)
      .pipe(
        tap(expandedState => this.collapseRef.setExpandedState(expandedState)),
        shareReplay(1),
      );

    this.verifiedProcedureCapture$ = this.historicalProcedureCaptureSelectors
      .getByDocumentId(this.documentId)
      .pipe(
        filterTruthy(),
        filter(isProcedureCaptureVerified),
        tap(() => {
          this.historicalProcedureCaptureActions.collapseForm(this.documentId);
        }),
      );
  }

  private listenForUpdateProcedureEvents(): void {
    this.isCaptureFormExpanded$
      .pipe(
        filter(isExpanded => isExpanded),
        withLatestFrom(this.verifiedProcedureCapture$),
        map(([_, procedureCapture]) => procedureCapture),
        filterTruthy(),
        tap(procedureCapture => this.setProcedureCaptureForm(procedureCapture)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  private setProcedureCaptureForm(procedure: ProcedureCapture): void {
    this.form.controls.patchValue({
      procedureFhirId: procedure.id,
      procedureCode: procedure.code?.coding[0]?.code || null,
      procedureDate: procedure.performedDateTime
        ? utcDateToLocalDate(procedure.performedDateTime)
        : null,
      procedureResult: (procedure.note && procedure.note[0].text) || null,
    });
  }
}
