import { Component, Input, OnInit } from '@angular/core';

import {
  ControlOption,
  DynamicFormGroup,
  SearchableFormGroup,
} from './../../../utils/forms/base';

@Component({
  selector: 'omg-searchable-auto-complete',
  templateUrl: './searchable-auto-complete.component.html',
  styleUrls: ['./searchable-auto-complete.component.css'],
})
export class SearchableAutoCompleteComponent {
  @Input() form: SearchableFormGroup & DynamicFormGroup;

  constructor() {}

  trackByName(index, option: ControlOption<any>) {
    return option.name;
  }
}
