import { NgModule } from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { AuthService } from '@auth0/auth0-angular';
import { APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { ConfigService } from '@app/core/config';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

function onelife(
  configService: ConfigService,
  httpLink: HttpLink,
): ApolloClientOptions<any> {
  const uri = `${configService.environment.oauth2.providerUrl}/api/graphql`;
  const link = httpLink.create({ uri });
  return {
    link,
    cache: new InMemoryCache(),
  };
}

function cdp(
  configService: ConfigService,
  httpLink: HttpLink,
): ApolloClientOptions<any> {
  const uri = `${configService.environment.grafhir.endpoint}`;
  const link = httpLink.create({ uri });
  return {
    link,
    cache: new InMemoryCache(),
  };
}

function appsync(
  configService: ConfigService,
  authService: AuthService,
): ApolloClientOptions<any> {
  const { apiUrl, region } = configService.environment.appSync;
  const link = ApolloLink.from([
    createAuthLink({
      url: apiUrl,
      region,
      auth: {
        type: 'OPENID_CONNECT',
        jwtToken: async () => authService.getAccessTokenSilently().toPromise(),
      },
    }),
    createSubscriptionHandshakeLink(apiUrl),
  ]);
  return {
    link,
    cache: new InMemoryCache(),
  };
}

function factory(
  httpLink: HttpLink,
  configService: ConfigService,
  authService: AuthService,
): NamedOptions {
  return {
    onelife: onelife(configService, httpLink),
    cdp: cdp(configService, httpLink),
    appsync: appsync(configService, authService),
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: factory,
      deps: [HttpLink, ConfigService, AuthService],
    },
  ],
})
export class GraphQLModule {}
