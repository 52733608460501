import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { FeatureFlagSelectors } from '@app/core';
import { FeatureFlag } from '@app/core/feature-flag/shared/feature-flag.type';
import { FeatureFlagActions } from '@app/core/feature-flag/store/feature-flag.actions';
import { filterTruthy } from '@app/utils';

@Injectable()
export class FeatureFlagResolver implements Resolve<FeatureFlag[] | boolean> {
  constructor(
    private featureFlagActions: FeatureFlagActions,
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {}

  resolve() {
    this.featureFlagActions.getFeatureFlags();
    return <Observable<FeatureFlag[] | boolean>>(
      this.featureFlagSelectors.featureFlags.pipe(
        filterTruthy(),
        take(1),
        catchError(() => of(false)),
      )
    );
  }
}
