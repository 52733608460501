import Perfume from 'perfume.js';
import { IPerfumeOptions } from 'perfume.js/dist/types/types';

function logPerfume(options): void {
  const style = 'color:#ff6d00;font-size:11px;';
  // eslint-disable-next-line no-console
  console.log(`%c Perfume.js: ${options.metricName} `, style, options.data);
}

export function perfumeAnalyticsTracker(options: Partial<any>) {
  const { metricName, data } = options;
  logPerfume(options);
}

export const PerfumeConfig: IPerfumeOptions = {
  analyticsTracker: perfumeAnalyticsTracker,
};

export const perfume = new Perfume(PerfumeConfig);
