import { gql } from 'apollo-angular';

import { InternalUser } from './internal-user.type';

export interface InternalUsersResponseData {
  internalUsers: {
    nodes: InternalUser[];
  };
}

export const GET_INTERNAL_USERS_BY_IDS = gql`
  query InternalUsers($ids: [ID!]) {
    internalUsers(ids: $ids) {
      nodes {
        id
        firstName
        lastName
        suffix
        initials
        profileImageUrl
        preferredRole {
          id
          name
        }
        nonPreferredRoles {
          id
        }
        workSchedule {
          id
          returningOn
          mondaysOff
          tuesdaysOff
          wednesdaysOff
          thursdaysOff
          fridaysOff
          saturdaysOff
          sundaysOff
        }
        defaultOffice {
          id
          name
        }
        serviceArea {
          id
          name
        }
      }
    }
  }
`;
