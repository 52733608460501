import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import {
  MedicationRegimen,
  MedicationRegimenForm,
  PatientMedicationForm,
} from '@app/modules/medications/shared';
import { FrequencyInterval } from '@app/modules/medications/shared/frequency-interval.type';
import { MedicationDispensable } from '@app/modules/medications/shared/medications.type';
import { Prn } from '@app/modules/medications/shared/prn.type';
import { head } from '@app/utils';

import {
  displayedDose,
  displayedDosingUnitOfMeasure,
  nullRegimenTextDescriptionPlaceholder,
} from './dose-utils';

export const freeTextLengthMax = 140;
export const v2FreeTextLengthMax = 1000;
export const prnDescriptionPrefix = ' as needed for ';
export type RegimenFormData = PatientMedicationForm | MedicationRegimenForm;

export enum RegimenFormControlNames {
  dose = 'dose',
  dispensableId = 'dispensableId',
  durationDays = 'durationDays',
  frequencyIntervalId = 'frequencyIntervalId',
  instructionsText = 'instructionsText',
  isCustomRegimen = 'isCustomRegimen',
  medicationRouteId = 'medicationRouteId',
  prescriptionTemplateId = 'prescriptionTemplateId',
  prescriptionTemplateItemId = 'prescriptionTemplateItemId',
  prnDescription = 'prnDescription',
  prnId = 'prnId',
  medicationRegimenId = 'medicationRegimenId',
  regimenTextDescription = 'regimenTextDescription',
  useInstructionsText = 'useInstructionsText',
  usePrn = 'usePrn',
}

export const findRegimenById = (
  regimenId: number,
  regimens: MedicationRegimen[],
): MedicationRegimen => regimens.find(regimen => regimen.id === regimenId);

export const mapRegimensToDropdownItems = (regimens: MedicationRegimen[]) =>
  regimens.map(
    ({ id: value, description: label }: Partial<MedicationRegimen>) => ({
      value,
      label,
    }),
  );

export const findDispensableById = (
  dispensableId: number,
  dispensables: MedicationDispensable[],
): MedicationDispensable =>
  dispensables.find(dispensable => dispensable.id === dispensableId);

export const findFrequencyIntervalById = (
  id: number,
  frequencyIntervals: FrequencyInterval[],
): FrequencyInterval => {
  if (!frequencyIntervals) {
    return null;
  }

  return frequencyIntervals.find(
    (interval: { id: number }) => interval.id === id,
  );
};

export const findPrnById = (id: number, prns: Prn[]): Prn => {
  if (!prns) {
    return null;
  }
  return prns.find(i => i.id === id);
};

const freeTextRegimenDescription = (
  dispensable: MedicationDispensable,
  freeText: string,
) => {
  if (!!dispensable) {
    return `${dispensable.description}${freeText ? `, ${freeText}` : ''}`;
  } else {
    return null;
  }
};

const prnTextDescription = (
  prns: Prn[],
  prnValue: { id: number; desc: string },
): string => {
  const prn = findPrnById(prnValue.id, prns);

  if (prn) {
    return prn.desc;
  } else {
    return prnValue.desc;
  }
};

const structuredDataDescription = (
  dispensables: MedicationDispensable[] = [],
  frequencyIntervals: FrequencyInterval[] = [],
  form: RegimenFormData,
) => {
  const dispensable = findDispensableById(form.dispensableId, dispensables);
  const dose = form.dose;
  const frequencyInterval = findFrequencyIntervalById(
    form.frequencyIntervalId,
    frequencyIntervals,
  );

  const durationDays = form.durationDays;
  const durationExists = !!durationDays;
  const durationDaysDescription = ` for ${durationDays} ${
    durationDays === 1 ? 'day' : 'days'
  }`;

  const dispensableDescription = dispensable && dispensable.description;
  const clinicalRoute = dispensable && dispensable.clinicalRoute;
  const clinicalDescription = clinicalRoute && clinicalRoute.description;

  const fullDispensableDescription = dispensable
    ? `${
        dispensableDescription ? `${dispensableDescription}, ` : ''
      }${displayedDose(dose)} ${displayedDosingUnitOfMeasure(
        dose,
        dispensable.dosingUnitOfMeasure,
      )} ${clinicalDescription} `
    : `${displayedDose(dose)} `;

  const durationDescription = `${
    durationExists ? durationDaysDescription : ''
  }`;

  const frequencyIntervalDescription = frequencyInterval
    ? frequencyInterval.textShort
    : '';

  return !!dispensable
    ? `${fullDispensableDescription}${frequencyIntervalDescription}${durationDescription}`
    : null;
};

export const buildRegimenTextDescription = (
  dispensables: MedicationDispensable[] = [],
  frequencyIntervals: FrequencyInterval[] = [],
  prns: Prn[] = [],
  form: RegimenFormData,
  isFormValid: boolean = false,
) => {
  const dispensable = findDispensableById(form.dispensableId, dispensables);
  let textDescription: string;

  if (!isFormValid) {
    return nullRegimenTextDescriptionPlaceholder;
  }

  if (form.useInstructionsText && !!dispensable) {
    textDescription = freeTextRegimenDescription(
      dispensable,
      form.instructionsText,
    );
  } else {
    textDescription = structuredDataDescription(
      dispensables,
      frequencyIntervals,
      form,
    );
  }

  if (form.prnId && form.usePrn) {
    const prnValue = { id: form.prnId, desc: form.prnDescription };
    const prnDescription = prnTextDescription(prns, prnValue);
    if (prnDescription) {
      textDescription = `${textDescription}${prnDescriptionPrefix}${prnDescription}`;
    }
  }

  return !textDescription ||
    textDescription.includes('undefined') ||
    textDescription.includes('null')
    ? nullRegimenTextDescriptionPlaceholder
    : textDescription;
};

export const determineInitialMaxLength = (enabled: boolean): number =>
  enabled ? v2FreeTextLengthMax : freeTextLengthMax;

export const maxInstructionsTextLength = (
  launchDarklyService: LaunchDarklyService,
): Observable<number> =>
  launchDarklyService
    .variation$(FeatureFlagNames.meds1000CharInstructions, false)
    .pipe(map(enabled => determineInitialMaxLength(enabled)));

export const calculateFreeTextMaxLength = (
  prns: Prn[],
  form: RegimenFormData,
  maxLength: number,
) => {
  const prn: Prn = findPrnById(form.prnId, prns);
  const { prnId: formPrnId, prnDescription: formPrnDescription } = form;

  if (form.usePrn) {
    if (prn) {
      return maxLength - prnDescriptionPrefix.length - prn.desc.length;
    } else if ((!formPrnId || formPrnId === -1) && formPrnDescription) {
      return (
        maxLength - prnDescriptionPrefix.length - formPrnDescription.length
      );
    }
    return maxLength - prnDescriptionPrefix.length;
  }

  return maxLength;
};

export const getDefaultDispensable = (
  dispensables: MedicationDispensable[],
): MedicationDispensable => {
  return head(dispensables);
};

export const getRegimenDefaults = (
  regimen: MedicationRegimen,
  defaultDispensable: MedicationDispensable = <MedicationDispensable>{
    id: null,
  },
): Partial<PatientMedicationForm> => {
  const medicationRegimenId = regimen && regimen.id;
  const template = regimen && regimen.medicationPrescriptionTemplate;
  const dose = regimen && regimen.dose;
  const usePrn = (template && !!template.medicationPrn) || false;
  const { id: prnId, desc: prnDescription } = (template &&
    template.medicationPrn) || { id: null, desc: null };
  const templateItem = template && template.medicationPrescriptionItemTemplate;
  const prescriptionTemplateId = template && template.id;

  const prescriptionTemplateItemId = templateItem && templateItem.id;
  const frequencyIntervalId =
    (templateItem &&
      templateItem.medicationFrequencyInterval &&
      templateItem.medicationFrequencyInterval.id) ||
    null;
  const dispensableId =
    (templateItem && templateItem.medicationDispensableId) ||
    (defaultDispensable && defaultDispensable.id) ||
    null;

  return {
    isCustomRegimen: !medicationRegimenId,
    medicationRegimenId,
    prescriptionTemplateId,
    prescriptionTemplateItemId,
    dose,
    dispensableId,
    frequencyIntervalId,
    durationDays: null,
    usePrn,
    prnId,
    prnDescription,
  };
};

export const setRegimenFormValidator = ({
  control,
  enabled = false,
  validators = [Validators.required],
}: {
  control: AbstractControl;
  enabled?: boolean;
  validators?: ValidatorFn[];
}) => {
  control.setValidators(enabled ? validators : []);
  control.updateValueAndValidity({ emitEvent: false });
};
