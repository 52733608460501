import {
  htField,
  infWtField,
  wtField,
} from '@app/modules/growth-charts/growth-charts/growth-charts.type';
import { getWeightInKg } from '@app/modules/vitals-data/shared/percentile-calculator/child-weight-percentile';
import {
  VitalsData,
  VitalsDataMeasurement,
} from '@app/modules/vitals-data/shared/vitals-data.type';
import {
  compareDatesDesc,
  dateIsString,
  differenceInMonths,
  flatMap,
  parseISO,
  toDate,
} from '@app/utils';

export interface PediatricVital {
  type?: string;
  collectedAt?: string;
  value?: string;
  label: string;
  category?: string;
  isExpired?: boolean;
}

export interface PediatricVitalWarning {
  isError?: boolean;
  expiredVitals?: string;
  expiryRequirements?: object;
}

export interface ExpiryRequirement {
  expiryPeriod: string;
  ageRange: string;
}

const vitalsLabels = {
  [infWtField]: 'Infant Weight',
  [wtField]: 'Weight',
  [htField]: 'Height',
};

const vitalsCategories = {
  weight: [wtField, infWtField],
  height: [htField],
};

const enum ExpiryPeriod {
  threeMonths = 3,
  sixMonths = 6,
  twelveMonths = 12,
}

const expiryRequirements = {
  [ExpiryPeriod.threeMonths]: {
    expiryPeriod: '3 months',
    ageRange: 'under 24 months old',
  },
  [ExpiryPeriod.sixMonths]: {
    expiryPeriod: '6 months',
    ageRange: '24-36 months old',
  },
  [ExpiryPeriod.twelveMonths]: {
    expiryPeriod: '12 months',
    ageRange: '3–18 years old',
  },
};

const formatValue = (measurement: VitalsDataMeasurement): string => {
  const metricValue =
    measurement.unit === 'lb'
      ? ` (${getWeightInKg(measurement.value).toFixed(2)} kg)`
      : '';
  return `${measurement.value} ${measurement.unit}${metricValue}`;
};

const mapVitalsDataToPediatricVitals = (
  vitals: VitalsData[],
): PediatricVital[] =>
  flatMap(
    (vital: VitalsData) =>
      vital.measurements.map((measurement: VitalsDataMeasurement) => {
        const type = measurement.measurementType.abbreviation;
        return {
          type,
          collectedAt: vital.collectedAt,
          value: formatValue(measurement),
          label: vitalsLabels[type],
        };
      }),
    vitals,
  ).sort((a: PediatricVital, b: PediatricVital) =>
    compareDatesDesc(parseISO(a.collectedAt), parseISO(b.collectedAt)),
  );

const getExpiryPeriod = (ageInMonths: number): ExpiryPeriod => {
  if (ageInMonths < 24) {
    return ExpiryPeriod.threeMonths;
  }

  if (ageInMonths < 36) {
    return ExpiryPeriod.sixMonths;
  }

  return ExpiryPeriod.twelveMonths;
};

const findMostRecentVital = (
  vitals: PediatricVital[],
  category: string,
): PediatricVital =>
  vitals.find((vital: PediatricVital) =>
    vitalsCategories[category].includes(vital.type),
  ) || { label: category };

const isExpired = (
  date: string | Date,
  expiryInMonths: ExpiryPeriod,
): boolean => {
  const calculatedDate = dateIsString(date)
    ? parseISO(`${date}`)
    : toDate(<Date>date);
  const difference = differenceInMonths(new Date(), calculatedDate);
  return !date || difference >= expiryInMonths;
};

export const isMissingVitals = (vitals: PediatricVital[]): boolean =>
  vitals.some(vital => !vital.value);

export const getExpiryRequirements = (ageInMonths: number): ExpiryRequirement =>
  expiryRequirements[getExpiryPeriod(ageInMonths)];

export const getExpiredVitalsWarning = (vitals: PediatricVital[]): string =>
  vitals
    .filter((vital: PediatricVital) => vital.isExpired)
    .map((vital: PediatricVital) => vital.category)
    .join(' and ');

export const getMostRecentVitals = (
  vitalsData: VitalsData[],
  ageInMonths: number,
): PediatricVital[] => {
  const vitals: PediatricVital[] = mapVitalsDataToPediatricVitals(vitalsData);
  return Object.keys(vitalsCategories).map((category: string) => {
    const vital = findMostRecentVital(vitals, category);
    vital.category = category;
    vital.isExpired = isExpired(
      vital.collectedAt,
      getExpiryPeriod(ageInMonths),
    );
    return vital;
  });
};
