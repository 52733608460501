<div class="omg-dialog-container"
     style="width: 560px"
     data-cy="help-request-modal"
     omgTheme>
  <div omgDialogTitleDirective>
    <h5 class="help-request-confirmation-title">Create HelpOut Request</h5>
    <button omgButton
            variant="none"
            class="om-icon icon-close clickable"
            (click)="close()"></button>
  </div>
  <div omgDialogContentDirective
       om-layout="vertical"
       om-layout-gutter
       class="padding-normal">
    <p>
      Your request will be submitted to find an available MSS to assist with this patient.
      <a [href]="helpOutWikiLink"
         target="_blank"
         data-cy="help-out-wiki-link">Learn more.</a>
    </p>
    <form [formGroup]="form.controls">
      <div om-layout="vertical"
           om-layout-gutter>
        <label class="plain-text">
          What kind of help request is this?
          <omg-dropdown formControlName="helpflowType"
                        [options]="form.helpFlowTypes"
                        [autoDisplayFirst]="false"
                        data-cy="helpflow-type-dropdown"
                        [flex]="true"></omg-dropdown>
        </label>
        <label class="plain-text">
          Urgency Level
          <omg-dropdown formControlName="urgency"
                        [options]="form.urgencyLevels"
                        data-cy="helpflow-type-dropdown"
                        [flex]="true"></omg-dropdown>
        </label>
        <label class="plain-text"
               om-layout="vertical">
          Facility (optional)
          <input omgInputText
                 formControlName="facility"
                 [placeholder]="'Enter facility name'"
                 data-cy="helpflow-facility-field"
                 om-flex />
        </label>
        <omg-checkbox label="Is the patient/caller with you now?"
                      data-cy="caller-on-the-line-checkbox"
                      [plainLabel]="true"
                      formControlName="callerOnTheLine"></omg-checkbox>

        <label class="plain-text">
          Add some details about this request
          <textarea omgTextarea
                    autosize
                    rows="3"
                    om-layout-fill
                    data-cy="help-out-detail-text-area"
                    formControlName="details"
                    [maxlength]="form.maxDetailsLength"></textarea>
          <span class="caption"
                data-cy="help-out-detail-remaining-characters">
            {{ form.controls.get('details').value | omgRemainingCharacters: form.maxDetailsLength }} characters
            remaining
          </span>
        </label>

        <div class="marked">
          Be sure to include what needs to happen (e.g. "Sign this order" or "Report stat lab results")
        </div>
      </div>
    </form>
  </div>
  <div omgDialogActionsDirective>
    <button omgButton
            variant="primary"
            data-cy="submit-help-request-button"
            [disabled]="form.controls.invalid || saving"
            (click)="sendRequest()">
      Request Help
    </button>
  </div>
</div>
