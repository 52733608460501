import { UntypedFormGroup } from '@angular/forms';
import { defaultTo, isEqual } from 'lodash/fp';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  switchMap,
  tap,
} from 'rxjs/operators';

export type FormModelSaveFunction = (any) => Observable<any>;
export type FormModelSavePredicate = (
  currentValue: any,
  previousValue: any,
) => boolean;
export type FormModelMapSaveError = (any) => any;

export type FormModelState =
  | 'pristine'
  | 'savePending'
  | 'saving'
  | 'saveError'
  | 'saved';

export const FormModelStateValues: FormModelState[] = [
  'pristine',
  'savePending',
  'saving',
  'saveError',
  'saved',
];

export interface FormModelStateChange {
  state: FormModelState;
  value: any;
  error: any;
}

export type FormModelOptions = Partial<
  Readonly<{
    form: UntypedFormGroup;
    autosave: boolean;
    autosaveDelay: number;
    saveInvalid: boolean;
    mapSaveError: FormModelMapSaveError;
    resetOnSave: boolean;
    saveFunction: FormModelSaveFunction;
    savePredicate: FormModelSavePredicate;
  }>
>;

/**
 * FormModel
 *
 * The model wraps a FormGroup and provides an abstration layer to support
 * complex workflows such as autosave, cancel, etc.
 *
 * NOTE: This is implemented as a simple finite state machine.
 *
 */
export class FormModel implements FormModelOptions {
  autosave = true;
  autosaveDelay = 300;
  mapSaveError: FormModelMapSaveError;
  saveInvalid = false;
  saveFunction: FormModelSaveFunction;
  savePredicate: FormModelSavePredicate;
  resetOnSave = false;

  private _currentState: FormModelState;
  private _form: UntypedFormGroup;
  private _ignoreValueChange = false;
  private _initialValue: any;
  private _lastSaveError: any;
  private _lastSaveValue: any;
  private _stateSubject = new ReplaySubject<FormModelStateChange>(1);
  private _stateChanges = this._stateSubject.asObservable();
  private _subscription: Subscription;

  constructor(form: UntypedFormGroup, options: FormModelOptions = {}) {
    this.configure({ ...options, form });
  }

  dispose() {
    this.unsubscribeFromFormChanges();
  }

  configure(options: FormModelOptions = {}) {
    this._form = defaultTo(this._form, options.form);
    this.autosave = defaultTo(this.autosave, options.autosave);
    this.autosaveDelay = defaultTo(this.autosaveDelay, options.autosaveDelay);
    this.mapSaveError = defaultTo(this.mapSaveError, options.mapSaveError);
    this.saveInvalid = defaultTo(this.saveInvalid, options.saveInvalid);
    this.saveFunction = defaultTo(this.saveFunction, options.saveFunction);
    this.resetOnSave = defaultTo(this.resetOnSave, options.resetOnSave);
    this.savePredicate = defaultTo(this.savePredicate, options.savePredicate);
  }

  init() {
    if (!this._form) {
      throw Error('FormGroup is required');
    }

    this.unsubscribeFromFormChanges();
    this.markAsPristine();
    this.subscribeToFormChanges();
  }

  get form() {
    return this._form;
  }

  get(path: string | (string | number)[]) {
    return this.form.get(path);
  }

  get value() {
    return this.form.value;
  }

  get state() {
    return this._currentState;
  }

  get stateChanges() {
    return this._stateChanges;
  }

  get saveError() {
    return this._lastSaveError;
  }

  get saving() {
    return (
      this._currentState === 'saving' || this._currentState === 'savePending'
    );
  }

  setValue(value: any) {
    this.runOperation(() => this._form.setValue(value));
  }

  patchValue(value: any) {
    this.runOperation(() => this._form.patchValue(value));
  }

  markAsPristine() {
    this.runOperation(() => {
      this.setState('pristine');
    });
  }

  reset() {
    this.runOperation(() => {
      this._form.reset();
      this.setState('pristine');
    });
  }

  cancel() {
    this.runOperation(() => {
      this._form.setValue(this._initialValue);
      this.setState('pristine');
    });
  }

  save(subscribe = true) {
    if (!subscribe) {
      return this.saveFormValue(this.form.value);
    }

    // We assume that the save function is a cold observable
    this.saveFormValue(this.form.value).subscribe();
  }

  private setState(
    state: FormModelState,
    value: any = null,
    error: any = null,
  ) {
    value = value || this.form.value;

    if (state === 'pristine') {
      this._initialValue = value;
      this._lastSaveValue = value;
      this._lastSaveError = null;
      this._form.markAsPristine();
    } else if (state === 'saving') {
      this._lastSaveValue = value;
      this._lastSaveError = null;
    } else if (state === 'saved') {
      this._lastSaveValue = value;
      this._lastSaveError = null;
    } else if (state === 'saveError') {
      this._lastSaveValue = value;
      this._lastSaveError = error;
    }

    if (this._currentState !== state) {
      this._currentState = state;
      this._stateSubject.next({ state, value, error });
    }
  }

  private canSave(value) {
    const defaultSavePredicate = () => true;
    const savePredicate = this.savePredicate || defaultSavePredicate;

    const canSaveResult =
      savePredicate(value, this._lastSaveValue) &&
      this.autosave &&
      (this.saveInvalid ? true : this._form.valid) &&
      this._currentState !== 'saving' &&
      !isEqual(value, this._lastSaveValue);

    if (!canSaveResult) {
      this.setState('saved', this._lastSaveValue);
    }

    return canSaveResult;
  }

  private saveFormValue(value: any) {
    if (!this.saveFunction) {
      throw Error('Unable to save without saveFunction');
    }

    this.setState('saving', value);

    const defaultMapError = e => e;
    const mapError = this.mapSaveError || defaultMapError;

    return this.saveFunction(value).pipe(
      catchError(error => {
        this.setState('saveError', value, mapError(error));
        return of(error);
      }),
      tap(() => {
        if (this._currentState !== 'saveError') {
          this.setState('saved', value);
          if (this.resetOnSave) {
            this.reset();
          }
        }
      }),
    );
  }

  private subscribeToFormChanges() {
    this._subscription = this.form.valueChanges
      .pipe(
        filter(() => !this._ignoreValueChange),
        tap(value => this.setState('savePending', value)),
        debounceTime(this.autosaveDelay),
        filter(value => this.canSave(value)),
        switchMap(value => this.saveFormValue(value)),
      )
      .subscribe();
  }

  private unsubscribeFromFormChanges() {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
  }

  private runOperation(operation: () => void, allowSave = false) {
    if (allowSave) {
      operation();
    } else {
      this._ignoreValueChange = this._ignoreValueChange || true;
      operation();
      this._ignoreValueChange = !this._ignoreValueChange || false;
    }
  }
}
