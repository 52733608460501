import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  AnalyticsActions,
  Patient,
  PatientSelectors,
  ProfileSelectors,
} from '@app/core';
import { AnalyticsEvent } from '@app/core/analytics/shared/analytics.type';
import { mapNoteTypeToAnalyticsSubcomponent } from '@app/features/timeline/shared/timeline.mapper';
import { NoteApiService } from '@app/modules/note/shared/note-api.service';
import { Note } from '@app/modules/note/shared/note.type';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { TodoSelectors } from '@app/modules/todo/store/todo.selectors';
import { filterTruthy } from '@app/utils';

import { PatientChartActions } from '../../../patient-chart/store/patient-chart.actions';
import { PatientChartSelectors } from '../../../patient-chart/store/patient-chart.selectors';
import { isWalkinVisit } from '../../shared/summaries-utils';
import { SummariesGuard } from '../../shared/summaries.guard';
import { Summary } from '../../shared/summaries.type';
import { SummariesActions } from '../../store/summaries.actions';
import { SummariesSelectors } from '../../store/summaries.selectors';

@Component({
  selector: 'omg-summaries',
  templateUrl: './summaries.component.html',
  styleUrls: ['./summaries.component.scss'],
})
export class SummariesComponent implements OnInit, OnDestroy {
  summary: Observable<Summary>;
  todo: Observable<Todo>;
  patient: Observable<Patient>;
  profilePrimaryName: Observable<string>;
  hasIncompleteCosignTask: Observable<boolean>;
  note: Observable<Note>;

  workspaceExpanded$: Observable<boolean>;

  private unsubscribe = new Subject();

  constructor(
    private summarySelectors: SummariesSelectors,
    private todoSelectors: TodoSelectors,
    private summariesActions: SummariesActions,
    private patientChartSelectors: PatientChartSelectors,
    private patientChartActions: PatientChartActions,
    private patientSelectors: PatientSelectors,
    private profileSelectors: ProfileSelectors,
    private noteApiService: NoteApiService,
    private analytics: AnalyticsActions,
    private summariesGuard: SummariesGuard,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.summariesGuard
      .canActivate(this.route.snapshot)
      .pipe(
        filterTruthy(),
        tap(() => this.setupSelectors()),
      )
      .subscribe();
  }

  /* istanbul ignore next */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.closeServiceTicket();
  }

  onCloseSummary(summary: Summary) {
    this.trackCloseItem(summary);
    this.closeServiceTicket();
    this.summariesActions.closeWorkspaceItem();
  }

  closeServiceTicket() {
    this.patientChartActions.expandWorkspace(false);
  }

  get isNoteOrWalkinVisit(): Observable<boolean> {
    return this.summary.pipe(
      map(
        summary =>
          !summary.appointment ||
          (summary.noteType && isWalkinVisit(summary.noteType)) ||
          summary.hasProgramVisit,
      ),
    );
  }

  get isAppointmentSummary(): Observable<boolean> {
    return this.summary.pipe(
      map(
        summary =>
          !!summary.appointment &&
          !(summary.noteType && isWalkinVisit(summary.noteType)) &&
          !summary.hasProgramVisit,
      ),
    );
  }

  private setupSelectors() {
    this.patient = this.patientSelectors.patient;

    this.profilePrimaryName = this.profileSelectors.primaryIdentityName;

    this.workspaceExpanded$ = this.patientChartSelectors.workspaceExpanded;

    this.summary = this.summarySelectors.currentSummary.pipe(
      filterTruthy(),
      tap((summary: Summary) => {
        this.todo = this.todoSelectors.todoBySummaryId(summary.id).pipe(
          filterTruthy(),
          tap((todo: Todo) => {
            this.hasIncompleteCosignTask = this.todoSelectors
              .isIncompleteAndCosign(todo.id)
              .pipe(
                map(
                  isIncompleteAndCosign =>
                    isIncompleteAndCosign && summary.signed,
                ),
              );
          }),
        );

        this.note = this.noteApiService.get(summary.note.id);
      }),
    );
  }

  private trackCloseItem(summary: Summary): void {
    this.analytics.trackEvent(AnalyticsEvent.ClinicalTimelineObjectClosed, {
      workflow: 'Charting',
      component: 'Clinical Timeline',
      activeCharting: true,
      subcomponent: mapNoteTypeToAnalyticsSubcomponent(summary.noteType.name),
      noteId: summary.note.id,
      orderId: null,
      summaryId: summary.id,
      patientTimelinePostId: null,
      method: 'Push',
    });
  }
}
