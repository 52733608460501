import { NgModule } from '@angular/core';

import { PresenceModule } from '@app/features/presence/presence.module';
import { SharedModule } from '@app/shared';

import { AppNavbarComponent } from './components/app-navbar/app-navbar.component';
import { AppShellComponent } from './components/app-shell/app-shell.component';
import { EmptyComponent } from './components/empty/empty.component';
import { LoginComponent } from './components/login/login.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ProfileResolver } from './shared/profile.resolver';

const components = [
  AppShellComponent,
  AppNavbarComponent,
  EmptyComponent,
  LoginComponent,
  ScheduleComponent,
  UnauthorizedComponent,
];

@NgModule({
  imports: [SharedModule, PresenceModule],
  exports: [...components],
  declarations: [...components],
  providers: [ProfileResolver],
})
export class AppShellModule {}
