import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update,
} from '@ngrx/entity';
import { createFeatureSelector } from '@ngrx/store';

import { Todo } from '../shared/todo.type';
import { TodoAction, TodoActionTypes } from './todo.actions';

export const todoStatePath = 'todo';

export interface TodoState extends EntityState<Todo> {
  loading: boolean;
  error: any;
}

export const selectTodoState = createFeatureSelector<TodoState>(todoStatePath);

export function selectTodoId(todo: Todo): number {
  return todo.id;
}

export const adapter: EntityAdapter<Todo> = createEntityAdapter<Todo>({
  selectId: selectTodoId,
});

export const todoInitialState: TodoState = adapter.getInitialState({
  loading: false,
  error: null,
});

export const { selectEntities, selectAll } = adapter.getSelectors();

export function todoReducer(
  state = todoInitialState,
  action: TodoAction,
): TodoState {
  switch (action.type) {
    case TodoActionTypes.LOAD_TODO:
    case TodoActionTypes.LOAD_TODO_BY_ENTITY:
    case TodoActionTypes.LOAD_TODO_BY_SUMMARY_ID: {
      return {
        ...state,
        loading: true,
      };
    }

    case TodoActionTypes.LOAD_TODO_SUCCESS: {
      const removeOldTodoPredicate = (entity: Todo) => {
        const nextStepTodo =
          !!entity.nextStepId && entity.nextStepId === action.payload.id;
        const associatedWith =
          !!entity.associatedWithId &&
          entity.associatedWithId === action.payload.associatedWithId &&
          entity.associatedWithType === action.payload.associatedWithType;

        return nextStepTodo || associatedWith || false;
      };

      const stateWithoutOldTodos = adapter.removeMany(
        removeOldTodoPredicate,
        state,
      );

      return adapter.upsertOne(action.payload, {
        ...stateWithoutOldTodos,
        loading: false,
        error: null,
      });
    }

    case TodoActionTypes.LOAD_TODO_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case TodoActionTypes.UPDATE_TODO: {
      return {
        ...state,
        loading: true,
      };
    }

    case TodoActionTypes.UPDATE_TODO_SUCCESS: {
      const update: Update<Todo> = {
        id: action.payload.id,
        changes: action.payload,
      };

      return adapter.updateOne(update, {
        ...state,
        loading: false,
      });
    }

    case TodoActionTypes.UPDATE_TODO_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case TodoActionTypes.COMPLETE_TODO: {
      return {
        ...state,
        loading: true,
      };
    }

    case TodoActionTypes.COMPLETE_TODO_SUCCESS: {
      const update: Update<Todo> = {
        id: action.payload.id,
        changes: action.payload,
      };

      return adapter.updateOne(update, {
        ...state,
        loading: false,
      });
    }

    case TodoActionTypes.COMPLETE_TODO_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case TodoActionTypes.REOPEN_TODO: {
      return {
        ...state,
        loading: true,
      };
    }

    case TodoActionTypes.REOPEN_TODO_SUCCESS: {
      const update: Update<Todo> = {
        id: action.payload.id,
        changes: action.payload,
      };

      return adapter.updateOne(update, {
        ...state,
        loading: false,
      });
    }

    case TodoActionTypes.REOPEN_TODO_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    default: {
      return { ...state };
    }
  }
}
