import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import {
  VitalsByType,
  vitalTypes,
} from '@app/modules/vitals-data/shared/vitals-data.type';
import { wholeNumberValidator } from '@app/shared';

import {
  MeasurementValidationRules,
  VitalsValidationRanges,
} from './measurement-validation-rules.type';
import {
  createWarningsValidatorByType,
  diastolicConditionallyRequiredValidator,
  getMaxByType,
  getMinByType,
  systolicConditionallyRequiredValidator,
} from './vitals-utils';

export class VitalSetFormService {
  constructor() {}

  buildForm(
    vitals: VitalsByType = {},
    validationRules: MeasurementValidationRules,
    featureFlagEnabled: boolean,
    isMinor: boolean,
    validationRanges?: VitalsValidationRanges,
  ): UntypedFormGroup {
    const sharedArgs: [boolean, VitalsValidationRanges] = [
      featureFlagEnabled && !isMinor,
      validationRanges,
    ];

    const sharedMinMaxArgs: [
      MeasurementValidationRules,
      boolean,
      VitalsValidationRanges,
    ] = [validationRules, ...sharedArgs];
    return new UntypedFormGroup(
      {
        [vitalTypes.systolicPressure]: new UntypedFormControl(
          vitals[vitalTypes.systolicPressure]?.value,
          [
            Validators.pattern(/[0-9]{0,3}/),
            Validators.min(
              getMinByType(vitalTypes.systolicPressure, ...sharedMinMaxArgs),
            ),
            Validators.max(
              getMaxByType(vitalTypes.systolicPressure, ...sharedMinMaxArgs),
            ),
            createWarningsValidatorByType(
              vitalTypes.systolicPressure,
              ...sharedArgs,
            ),
            wholeNumberValidator(),
          ],
        ),
        [vitalTypes.diastolicPressure]: new UntypedFormControl(
          vitals[vitalTypes.diastolicPressure]?.value,
          [
            Validators.pattern(/[0-9]{0,3}/),
            Validators.min(
              getMinByType(vitalTypes.diastolicPressure, ...sharedMinMaxArgs),
            ),
            Validators.max(
              getMaxByType(vitalTypes.diastolicPressure, ...sharedMinMaxArgs),
            ),
            createWarningsValidatorByType(
              vitalTypes.diastolicPressure,
              ...sharedArgs,
            ),
            wholeNumberValidator(),
          ],
        ),
        [vitalTypes.heartRate]: new UntypedFormControl(
          vitals[vitalTypes.heartRate]?.value,
          [
            Validators.pattern(/[0-9]{0,3}/),
            Validators.min(
              getMinByType(vitalTypes.heartRate, ...sharedMinMaxArgs),
            ),
            Validators.max(
              getMaxByType(vitalTypes.heartRate, ...sharedMinMaxArgs),
            ),
            createWarningsValidatorByType(vitalTypes.heartRate, ...sharedArgs),
            wholeNumberValidator(),
          ],
        ),
        [vitalTypes.respiratoryRate]: new UntypedFormControl(
          vitals[vitalTypes.respiratoryRate]?.value,
          [
            Validators.pattern(/[0-9]{0,3}/),
            Validators.min(
              getMinByType(vitalTypes.respiratoryRate, ...sharedMinMaxArgs),
            ),
            Validators.max(
              getMaxByType(vitalTypes.respiratoryRate, ...sharedMinMaxArgs),
            ),
            createWarningsValidatorByType(
              vitalTypes.respiratoryRate,
              ...sharedArgs,
            ),
            wholeNumberValidator(),
          ],
        ),
        [vitalTypes.temperature]: new UntypedFormControl(
          vitals[vitalTypes.temperature]?.value,
          [
            Validators.pattern(/([0-9]{1,3}(\.[0-9]?)?)?/),
            Validators.min(
              getMinByType(vitalTypes.temperature, ...sharedMinMaxArgs),
            ),
            Validators.max(
              getMaxByType(vitalTypes.temperature, ...sharedMinMaxArgs),
            ),
            createWarningsValidatorByType(
              vitalTypes.temperature,
              ...sharedArgs,
            ),
          ],
        ),
        [vitalTypes.weight]: new UntypedFormControl(
          vitals[vitalTypes.weight]?.value,
          [
            Validators.pattern(/([0-9]{1,3}(.[0-9]?)?)?/),
            Validators.min(
              getMinByType(vitalTypes.weight, ...sharedMinMaxArgs),
            ),
            Validators.max(
              getMaxByType(vitalTypes.weight, ...sharedMinMaxArgs),
            ),
            createWarningsValidatorByType(vitalTypes.weight, ...sharedArgs),
          ],
        ),
        [vitalTypes.infantWeight]: new UntypedFormControl(
          vitals[vitalTypes.infantWeight]?.value,
          [
            Validators.pattern(/([0-9]{0,3}(.[0-9]{0,2})?)?/),
            Validators.min(0),
            Validators.max(500),
          ],
        ),
        [vitalTypes.weightPercentile]: new UntypedFormControl(
          vitals[vitalTypes.weightPercentile]?.value,
        ),
        [vitalTypes.height]: new UntypedFormControl(
          vitals[vitalTypes.height]?.value,
          [
            Validators.pattern(/([0-9]{1,2}(.[0-9]?)?)?/),
            Validators.min(
              getMinByType(vitalTypes.height, ...sharedMinMaxArgs),
            ),
            Validators.max(
              getMaxByType(vitalTypes.height, ...sharedMinMaxArgs),
            ),
            createWarningsValidatorByType(vitalTypes.height, ...sharedArgs),
          ],
        ),
        [vitalTypes.heightPercentile]: new UntypedFormControl(
          vitals[vitalTypes.heightPercentile]?.value,
        ),
        [vitalTypes.weightForLengthPercentile]: new UntypedFormControl(
          vitals[vitalTypes.weightForLengthPercentile]?.value,
        ),
        [vitalTypes.bodyMassIndex]: new UntypedFormControl(
          vitals[vitalTypes.bodyMassIndex]?.value,
          [
            Validators.pattern(/([0-9]{1,3}(.[0-9]?)?)?/),
            Validators.min(0),
            Validators.max(99),
          ],
        ),
        [vitalTypes.bmiPercentile]: new UntypedFormControl(
          vitals[vitalTypes.bmiPercentile]?.value,
        ),
        [vitalTypes.headCircumference]: new UntypedFormControl(
          vitals[vitalTypes.headCircumference]?.value,
          [
            Validators.pattern(/([0-9]{0,2}(.[0-9]?)?)?/),
            Validators.min(0),
            Validators.max(99.9),
          ],
        ),
        [vitalTypes.headCircumferencePercentile]: new UntypedFormControl(
          vitals[vitalTypes.headCircumferencePercentile]?.value,
        ),
        [vitalTypes.pulseOximetry]: new UntypedFormControl(
          vitals[vitalTypes.pulseOximetry]?.value,
          [
            Validators.min(
              getMinByType(vitalTypes.pulseOximetry, ...sharedMinMaxArgs),
            ),
            Validators.max(
              getMaxByType(vitalTypes.pulseOximetry, ...sharedMinMaxArgs),
            ),
            createWarningsValidatorByType(
              vitalTypes.pulseOximetry,
              ...sharedArgs,
            ),
          ],
        ),
        [vitalTypes.oxygenConcentration]: new UntypedFormControl(
          vitals[vitalTypes.oxygenConcentration]?.value,
          [
            Validators.min(
              getMinByType(vitalTypes.oxygenConcentration, ...sharedMinMaxArgs),
            ),
            Validators.max(
              getMaxByType(vitalTypes.oxygenConcentration, ...sharedMinMaxArgs),
            ),
            createWarningsValidatorByType(
              vitalTypes.oxygenConcentration,
              ...sharedArgs,
            ),
          ],
        ),
      },
      {
        validators: [
          systolicConditionallyRequiredValidator,
          diastolicConditionallyRequiredValidator,
        ],
      },
    );
  }
}
