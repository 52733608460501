import { Component, Input, ViewChild } from '@angular/core';

import { PopoverPlacement } from '@app/shared/components/popover/popover-config';

import { PopoverTriggerDirective } from '../popover';

@Component({
  selector: 'omg-internal-user-info',
  templateUrl: './internal-user-info.component.html',
})
export class InternalUserInfoComponent {
  @Input() providerId: number;
  @Input() placement: PopoverPlacement = 'right';
  @Input() offsetX = 0;
  @Input() offsetY = 0;

  @ViewChild(PopoverTriggerDirective, { static: true })
  triggerDirective: PopoverTriggerDirective;

  resize() {
    this.triggerDirective.toggle();
    this.triggerDirective.toggle();
  }
}
