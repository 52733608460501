import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { PatientSelectors } from '@app/core';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';

import { HelpRequestForm } from './help-request-form';
import { HelpRequestService } from './help-request.service';
import { OrderHelpRequestForm } from './order-help-request-form';

@Injectable()
export class HelpRequestFormService {
  constructor(
    private helpRequestService: HelpRequestService,
    private patientSelectors: PatientSelectors,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  buildForm(unsubscribe$: Subject<void>) {
    return new HelpRequestForm(
      this.helpRequestService,
      this.patientSelectors,
      this.launchDarklyService,
      unsubscribe$,
    );
  }

  buildOrderHelpRequestForm(unsubscribe$: Subject<void>) {
    return new OrderHelpRequestForm(this.helpRequestService, unsubscribe$);
  }
}
