import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { getReactionSeverityText } from '@app/modules/allergies/shared/allergies-utils';

import { SummaryPatientAllergy } from '../../shared/summaries.type';

@Component({
  selector: 'omg-linked-allergies',
  templateUrl: './linked-allergies.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedAllergiesComponent {
  @Input() signed: boolean;
  @Input() noKnownAllergies: boolean;
  @Input() patientAllergies: SummaryPatientAllergy[];
  @Output() unlink = new EventEmitter();

  cehrtViewEnabled$: Observable<boolean>;

  constructor(private launchDarklyService: LaunchDarklyService) {
    this.cehrtViewEnabled$ = this.launchDarklyService
      .variation$(
        FeatureFlagNames.medsAllergiesAndReactionsCehrtRequirements,
        false,
      )
      .pipe(shareReplay(1));
  }

  reactionSeverityText = getReactionSeverityText;

  trackByFn = (index, patientAllergy: SummaryPatientAllergy) =>
    patientAllergy.id || index;

  deleteFromNote() {
    this.unlink.emit();
  }
}
