import {
  ChangeRx,
  RxChangeRequest,
  RxChangeRequestOption,
} from '@app/features/change-rx/shared/change-rx.type';
import {
  Renewal,
  RenewalCartResponse,
} from '@app/features/renewals/shared/renewals.type';
import {
  MedicationPrescriptionItemTemplate,
  MedicationRegimen,
} from '@app/modules/medications/shared/medications.type';
import { flow, isEqual, unionWith } from '@app/utils';

export type RxRequest = Renewal | RxChangeRequest | RxChangeRequestOption;

export const mergeUniquePackageOptions = (cartItem: RxRequest) => {
  const { packageOptions } = cartItem;

  const availableOptions = unionWith(isEqual, packageOptions.availableOptions, [
    packageOptions.defaultOption.matchedOption,
  ]);
  const fullOptions = {
    ...packageOptions,
    availableOptions,
  };

  const rxRequest = {
    ...cartItem,
    packageOptions: fullOptions,
  };
  return rxRequest;
};

const mapDates = (fullRxRequest: RxRequest): RxRequest => {
  const {
    earliestFillDate: earliestFillDateString,
    ...rxRequest
  } = fullRxRequest;
  let earliestFillDate;
  if (!earliestFillDateString) {
    earliestFillDate = null;
  } else if (typeof earliestFillDateString === 'string' && (<string>earliestFillDateString).length === 10) {
    earliestFillDate = new Date(`${earliestFillDateString} ${new Date().toLocaleTimeString()}`);
  } else {
    earliestFillDate = new Date(earliestFillDateString);
  }
  return { ...rxRequest, earliestFillDate };
};

const mapFrequencyIntervalData = (
  itemTemplate: MedicationPrescriptionItemTemplate,
): Partial<MedicationRegimen> => {
  const { doseLow, doseHigh } = itemTemplate;
  const frequencyInterval = itemTemplate.medicationFrequencyInterval || {
    id: null,
  };
  const frequencyIntervalData = {
    frequencyIntervalId: frequencyInterval.id,
    dose: null,
  };

  if (doseHigh) {
    frequencyIntervalData.dose = `${parseFloat(doseLow)}-${parseFloat(
      doseHigh,
    )}`;
  } else if (doseLow) {
    frequencyIntervalData.dose = parseFloat(doseLow);
  }

  return frequencyIntervalData;
};

const mapInstructionsText = (
  itemTemplate: MedicationPrescriptionItemTemplate,
): Partial<MedicationRegimen> => {
  return {
    instructionsText: itemTemplate && itemTemplate.instructionsText,

    useInstructionsText: !!(itemTemplate && itemTemplate.instructionsText),
  };
};

const mapItemTemplate = (
  itemTemplate: MedicationPrescriptionItemTemplate,
): Partial<MedicationRegimen> => {
  const { durationDays } = itemTemplate || { durationDays: null };
  const { medicationDispensableId: dispensableId } = itemTemplate;

  return {
    ...mapFrequencyIntervalData(itemTemplate),
    ...mapInstructionsText(itemTemplate),
    durationDays,
    dispensableId,
  };
};

const mapRegimenData = (rxRequest: RxRequest): Partial<RxRequest> => {
  const {
    medicationPrescriptionTemplate,
    isCustom: isCustomRegimen,
  } = rxRequest.medicationRegimen;
  const {
    id: prnId,
    desc: prnDescription,
  } = medicationPrescriptionTemplate.medicationPrn || { id: null, desc: null };

  return {
    ...rxRequest,
    medicationRegimen: {
      ...rxRequest.medicationRegimen,
      description: prnDescription,
      prnId,
      prnDescription,
      isCustomRegimen,
      ...mapItemTemplate(
        medicationPrescriptionTemplate.medicationPrescriptionItemTemplate,
      ),
    },
  };
};

const mapRouteData = (rxRequest: RxRequest): Partial<RxRequest> => {
  return {
    ...rxRequest,
    medicationRouteId: rxRequest.medicationRoute.id,
  };
};

export const mapRxRequestItem = (rxRequest: RxRequest): RxRequest =>
  <RxRequest>(
    flow(
      mapDates,
      mergeUniquePackageOptions,
      mapRegimenData,
      mapRouteData,
    )(rxRequest)
  );

export const mapRenewalsToRenewalEntities = (
  data: RenewalCartResponse,
): RenewalCartResponse => {
  const { cartItems: items } = data;

  const cartItems = items.map(mapRxRequestItem);
  return <any>{ ...data, cartItems };
};

export const mapRxChangeToRxChangeEntity = (rxChange: ChangeRx): ChangeRx =>
  rxChange.rxChangeRequest
    ? {
        ...rxChange,
        rxChangeRequest: <RxChangeRequest>{
          ...mapRxRequestItem(rxChange.rxChangeRequest),
          rxChangeRequestOptions: rxChange.rxChangeRequest.rxChangeRequestOptions.map(
            mapRxRequestItem,
          ),
        },
      }
    : rxChange;
