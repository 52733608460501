import { PatientAllergy } from '@app/modules/allergies/shared/allergies.type';

import { PatientMedication } from '../shared';
import { FrequencyInterval } from './frequency-interval.type';
import { Prn } from './prn.type';

export const newEntityId = -1;

export interface MedicationRegimen {
  id: number;
  medicationPrescriptionTemplate: MedicationPrescriptionTemplate;
  description?: string;
  dispensableId?: number;
  dose?: any;
  durationDays?: any;
  frequencyIntervalId?: number;
  instructionsText?: string;
  isActive?: boolean;
  isCustom?: boolean;
  isCustomRegimen?: boolean;
  medicationRouteId: number;
  prnDescription?: string;
  prnId?: number;
  textDesc?: string;
  useInstructionsText?: boolean;
}

export interface DosingUnitOfMeasureDescription {
  desc: string;
  descDiff?: string;
  descPlural?: string;
}

export interface ClinicalRoute {
  description: string;
  id: number;
  layDescription: string;
}

export interface MedicationDispensable {
  deaCode: string;
  dosingUnitOfMeasure: DosingUnitOfMeasureDescription;
  id: number;
  clinicalRoute: ClinicalRoute;
  restrictedControlledSubstance: boolean;
  description: string;
  maxDurationDays?: number;
  maxDurationDaysErrorMessage?: string;
}

export interface MedicationRoute {
  id: number;
  name: string;
  // optional
  isActive?: boolean;
  isCustom?: boolean;
  isGeneric?: boolean;
  legacy?: boolean;
  dispensables?: MedicationDispensable[];
  regimens?: MedicationRegimen[];
}

export interface MedicationRouteSummary {
  id: number;
  name: string;
  nameWithGenericSynonyms?: string;
  isGeneric?: boolean;
  additionalEmphasizedText?: string;
}

export interface MedicationPrescriptionItemTemplate {
  doseLow: string;
  durationDays: number;
  medicationDispensableId: number;
  id: number;
  // optional
  medicationFrequencyInterval?: FrequencyInterval;
  instructionsText?: string;
  doseHigh?: string;
}

export interface MedicationPrescriptionTemplate {
  id: number;
  medicationPrescriptionItemTemplate: MedicationPrescriptionItemTemplate;
  medicationPrn?: Prn;
}

export interface MedicationAllergy {
  name: string;
  patientAllergyId: number;
  severity: number;
  reaction?: string;
  comment?: string;
  crossSensitivityDescription?: string;
  interactionSeverity?: number;
  patientAllergy: PatientAllergy;
}

export interface MedicationInteraction {
  title: string;
  message: string;
  severityLevelMessage: string;
}

export interface InteractingMedication {
  interactingRoutedMedId: number;
  routedMedName: string;
  interactingRoutedMed: string;
  interactionCount: number;
  interactions: MedicationInteraction[];
}

export interface MedicationDrugInteraction {
  severityLevel: number;
  severityLevelTitle: string;
  interactingMeds: InteractingMedication[];
}

export interface MedicationsSectionLinkPayload {
  summaryData: {
    noMedications: boolean;
    patientMedicationRegimenIds: number[];
  };
}

/**
 * Forms
 */

export interface MedicationRegimenForm {
  medicationRouteId: number;
  medicationRegimenId: number;
  isCustomRegimen?: boolean;
  regimenTextDescription?: string;
  prescriptionTemplateId?: number;
  prescriptionTemplateItemId?: number;
  dispensableId: number;
  dose: string;
  durationDays: number;
  frequencyIntervalId: number;
  instructionsText?: string;
  useInstructionsText?: boolean;
  prnId?: number;
  prnDescription?: string;
  usePrn?: boolean;
}
