<div om-layout="vertical"
     omgTheme>
  <div om-layout
       om-layout-align="space-between center"
       om-flex>
    <span om-layout-gutter
          om-layout-align="space-between center"
          om-layout>
      <strong class="after-visit-guidance-title">After visit guidance:</strong>
      <span class="emphasized">visible to patient after note is signed</span>
    </span>
    <span>
      Optional
    </span>
  </div>
  <div>
    <omg-chart-text-box templateType="message"
                        [control]="afterVisitGuidance"
                        (init)="setCharacterCount($event)"
                        (textChange)="updateCharacterCount($event, false)"
                        quillFormat="html"
                        [insertionEventProps]="{
                                  component: 'Summary',
                                  subcomponent: 'After Visit Guidance'
                                }">
    </omg-chart-text-box>
    <div omgCollapse
         #actionBarCollapse="omgCollapse">
      <omg-collapsed>
        <div class="padding-half-vertical"
             om-layout
             om-layout-align="space-between start">
          <div om-layout
               om-layout-gutter
               om-layout-align="space-between center">
            <div om-layout
                 om-layout-gutter>
              <omg-template-insertion-button [actionBarCollapseRef]="actionBarCollapse"
                                             analyticsEventProperties="insertTemplateEventProperties">
              </omg-template-insertion-button>
              <omg-file-upload-button *ngIf="isAttachable"
                                      (fileChosen)="addAttachments($event)">
              </omg-file-upload-button>
            </div>
            <div om-layout="vertical"
                 om-layout-gutter>
              <div *ngFor="let attachment of summaryProviderRecommendation.s3Pointers">
                <omg-file-link *ngIf="!attachment.destroy"
                               [bucket]="attachment.bucket"
                               [key]="attachment.key"
                               [deletable]="true"
                               (delete)="deleteAttachment(attachment)"
                               [text]="attachment.title"></omg-file-link>
              </div>
            </div>
          </div>
          <p class="padding-half-vertical emphasized">{{characterCount}} characters</p>
        </div>
      </omg-collapsed>
      <omg-template-insertion-action-bar [collapseRef]="actionBarCollapse"
                                         [index]="templatesIndex"
                                         (apply)="onApplyTemplate($event)"></omg-template-insertion-action-bar>
    </div>
  </div>
</div>
