<ng-select class="om-auto-complete"
           [class.hide-input]="hideInput"
           [attr.om-layout-fill]="fillLayout ? true : null"
           [class.disable-arrow]="hideDropdownArrow"
           [class.disable-clear-all]="hideClearAll"
           [attr.om-flex]="flexValue ? flexValue : null"
           [loading]="loading"
           [searchable]="searchable"
           [selectOnTab]="selectOnTab"
           [placeholder]="placeholder"
           [bindLabel]="bindLabel"
           [bindValue]="bindValue"
           [addTagText]="addItemText"
           [addTag]="customItem"
           [items]="items"
           [appendTo]="'body'"
           [clearable]="clearable"
           [dropdownPosition]="dropdownPosition"
           [virtualScroll]="useVirtualScroll"
           [searchFn]="searchFn"
           (search)="onSearch($event)"
           (blur)="onBlur($event)"
           (change)="change.emit($event)"
           (clear)="clear.emit($event)"
           (open)="onOpen($event)"
           (close)="onClose($event)"
           [multiple]="multiple">
  <ng-container *ngIf="labelTemplate">
    <ng-template ng-label-tmp
                 let-item="item">
      <ng-template [ngTemplateOutlet]="labelTemplate"
                   [ngTemplateOutletContext]="{ item: item, clear: clearItem, label: item.label }">
      </ng-template>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="noResultsTemplate">
    <ng-template ng-notfound-tmp
                 let-searchTerm="searchTerm">
      <ng-template [ngTemplateOutlet]="noResultsTemplate"
                   [ngTemplateOutletContext]="{ searchTerm: searchTerm }">
      </ng-template>
    </ng-template>
  </ng-container>

  <ng-template ng-option-tmp
               let-optionData="item"
               let-search="searchTerm">
    <ng-container *ngIf="!highlightMatch"
                  [ngTemplateOutlet]="customDropdownTemplate ? customDropdownTemplate : default"
                  [ngTemplateOutletContext]="{ optionData: optionData }"></ng-container>
    <div [omgCustomOptionHighlight]="search"
         *ngIf="highlightMatch">
      <ng-container [ngTemplateOutlet]="customDropdownTemplate ? customDropdownTemplate : default"
                    [ngTemplateOutletContext]="{ optionData: optionData }"></ng-container>
    </div>
  </ng-template>

  <ng-template ng-multi-label-tmp
               let-items="items"
               let-clear="clear">
    <div *ngFor="let item of items">
      <ng-container [ngTemplateOutlet]="customMultiLabelTemplate || defaultMultiLabelTemplate"
                    [ngTemplateOutletContext]="{ item, clear }"></ng-container>
    </div>
  </ng-template>
</ng-select>

<ng-template #defaultMultiLabelTemplate
             let-item="item"
             let-clear="clear">
  <p-chip [removable]="true"
          (onRemove)="clear(item)"
          class="om-multi-label">
    <ng-container [ngTemplateOutlet]="labelTemplate || default"
                  [ngTemplateOutletContext]="{ item, clear, label: item.label, optionData: item }"></ng-container>
  </p-chip>
</ng-template>

<ng-template #default
             let-optionData="optionData">
  {{ optionData[bindLabel] }}
</ng-template>
