import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { ApiService } from '@app/core/api/api.service';
import { filterTruthy, mapValues } from '@app/utils';

import {
  GraphQLEndpointMetadata,
  ReferenceDataKeys,
  referenceDataMetadata,
  RestEndpointMetadata,
} from './reference-data.type';

enum ApiClientTypes {
  REST = 'rest',
  APOLLO = 'apollo',
}

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataApiService {
  constructor(
    private api: ApiService,
    private apollo: Apollo,
    private patientSelectors: PatientSelectors,
  ) {}

  getReferenceData(referenceDataKey: ReferenceDataKeys): Observable<any> {
    const metadata = referenceDataMetadata[referenceDataKey];

    switch (metadata.type) {
      case ApiClientTypes.REST:
        return this.getRestReferenceData(metadata);
      case ApiClientTypes.APOLLO:
        return this.getApolloReferenceData(metadata);
      default:
        return throwError('Unknown Api Client Type');
    }
  }

  private patientIdOptionToString(option, patientId) {
    return typeof option !== 'string'
      ? option
      : option.replace(':patientId', String(patientId));
  }

  private getRestReferenceData(metadata: RestEndpointMetadata) {
    return this.patientSelectors.patientId.pipe(
      filterTruthy(),
      switchMap(patientId =>
        this.api.get<any>(
          metadata.apiEndpoint.replace(':patientId', String(patientId)),
          {},
          {},
          true,
        ),
      ),
    );
  }

  private getApolloReferenceData(metadata: GraphQLEndpointMetadata) {
    return this.patientSelectors.patientId.pipe(
      filterTruthy(),
      switchMap(patientId =>
        this.apollo
          .use('onelife')
          .watchQuery({
            query: gql`
              ${metadata.graphQLQuery}
            `,
            variables: mapValues(
              option => this.patientIdOptionToString(option, patientId),
              metadata.graphQLQueryOptions,
            ),
          })
          .valueChanges.pipe(map(res => res.data[metadata.responseKey])),
      ),
    );
  }
}
