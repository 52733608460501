import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { Prescriber } from '@app/modules/shared-rx/prescriber-credential.type';
import { DropdownItem } from '@app/shared';

@Component({
  selector: 'omg-dea-license-number',
  templateUrl: './dea-license-number.component.html',
  styleUrls: ['./dea-license-number.component.css'],
})
export class DeaLicenseNumberComponent implements OnInit {
  @Input() validPrescriber: Prescriber;
  @Input() selectedPrescribingCredentialId: UntypedFormControl;
  @Input() credentialDropdownItems$: Observable<DropdownItem[]>;
  @Input() loading$: Observable<boolean>;
  showDropDown: boolean;
  unsubscribe$ = new Subject();
  credentialDropdownItems: DropdownItem[];

  constructor() {}

  ngOnInit() {
    this.setupSelectors();
  }

  setShowDropDown(credentialDropdownItems: DropdownItem[]) {
    this.showDropDown =
      this.validPrescriber &&
      credentialDropdownItems &&
      credentialDropdownItems.length > 1;
  }

  private setupSelectors() {
    this.credentialDropdownItems$
      .pipe(
        filter(items => !!items),
        tap(items => this.setShowDropDown(items)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(items => (this.credentialDropdownItems = items));
  }
}
