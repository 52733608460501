import { isDateBefore, startOfMonth, startOfYear } from '@app/utils';

import { formatDate, parseDate } from './date-fns';

export type FuzzyDatePrecision = 'invalid' | 'year' | 'month' | 'day';

export interface FuzzyDate {
  date: Date;
  dateFuzzy: string;
  precision: FuzzyDatePrecision;
}

interface FuzzyDatePrecisionMap {
  [p: number]: FuzzyDatePrecision;
}

const fuzzyDatePrecisionMap: FuzzyDatePrecisionMap = {
  0: 'invalid',
  1: 'year',
  2: 'month',
  3: 'day',
};

const fuzzyDateRegex = /^(\d{2}\/)?(\d{2}\/)?\d{4}$/;

const fuzzyDateFormat = (dateStr: string, type: FuzzyDatePrecision) => {
  if (type === 'year' && dateStr.length === 2) {
    return 'yy';
  } else if (type === 'year' && dateStr.length === 4) {
    return 'yyyy';
  } else if (type === 'month') {
    return 'MM/yyyy';
  } else if (type === 'day') {
    return 'MM/dd/yyyy';
  }

  return null;
};

export const isValidFuzzyDate = (dateStr: string): boolean =>
  fuzzyDateRegex.test(dateStr);

export const parseFuzzyDate = (dateStr: string): FuzzyDate => {
  if (!dateStr) {
    return { date: null, dateFuzzy: 'Invalid Date', precision: 'invalid' };
  }

  const precision =
    fuzzyDatePrecisionMap[dateStr.split('/').map(e => parseInt(e, 10)).length];

  const fuzzyFormat = fuzzyDateFormat(dateStr, precision);
  const date = parseDate(dateStr, fuzzyFormat, new Date());
  const dateFuzzy = dateStr;

  return { date, dateFuzzy, precision };
};

export const formatToLegacyFuzzyDatePrecision = (
  datePrecision: FuzzyDatePrecision,
) => {
  let precision;
  switch (datePrecision) {
    case 'year':
      precision = 1;
      break;
    case 'month':
      precision = 2;
      break;
    case 'day':
      precision = 3;
      break;
    default:
      return 1;
  }
  return precision;
};

export const formatFuzzyDate = (dateStr: string): FuzzyDate => {
  const fuzzyDate = parseFuzzyDate(dateStr);
  const date = fuzzyDate.date;
  const precision = fuzzyDate.precision;
  const dateFuzzy = date
    ? formatDate(fuzzyDate.date, 'yyyy-MM-dd')
    : fuzzyDate.dateFuzzy;
  return { date, dateFuzzy, precision };
};

// returns true if a < b; else false
export const isFuzzyDateBefore = (a: FuzzyDate, b: FuzzyDate): boolean => {
  const minimumPrecision = Math.min(
    formatToLegacyFuzzyDatePrecision(a.precision),
    formatToLegacyFuzzyDatePrecision(b.precision),
  );

  switch (minimumPrecision) {
    case 1:
      return isDateBefore(startOfYear(a.date), startOfYear(b.date));
    case 2:
      return isDateBefore(startOfMonth(a.date), startOfMonth(b.date));
    default:
      return isDateBefore(a.date, b.date);
      break;
  }
};
