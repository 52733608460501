import {
  FollowUpOrderAppointmentType,
  FollowUpOrderProvider,
} from '@app/features/follow-up-order/shared/follow-up-order.type';
import {
  MeasurementValidationRules,
  VitalsValidationRanges,
} from '@app/features/summaries/shared/measurement-validation-rules.type';
import { SurveyDetail } from '@app/features/survey-order/shared/survey-order.type';
import {
  InsurancePlan,
  Specialty,
} from '@app/modules/orders/shared/consultant-search/consultant-search.type';

import { ReferenceDataKeys } from '../shared/reference-data.type';
import {
  ReferenceDataAction,
  ReferenceDataActionTypes,
} from './reference-data.actions';

export const referenceDataPath = 'referenceData';

export interface ReferenceDataState {
  loading: boolean;
  error: any;
  [ReferenceDataKeys.followUpOrderProviders]: FollowUpOrderProvider[];
  [ReferenceDataKeys.followUpOrderAppointmentTypes]: FollowUpOrderAppointmentType[];
  [ReferenceDataKeys.surveys]: SurveyDetail[];
  [ReferenceDataKeys.allSpecialties]: Specialty[];
  [ReferenceDataKeys.measurementValidationRules]: MeasurementValidationRules;
  [ReferenceDataKeys.vitalsValidationRanges]: VitalsValidationRanges;
  [ReferenceDataKeys.consultantInsuranceTypes]: InsurancePlan[];
}

export const initialState: ReferenceDataState = {
  loading: false,
  error: null,
  [ReferenceDataKeys.followUpOrderProviders]: [],
  [ReferenceDataKeys.followUpOrderAppointmentTypes]: [],
  [ReferenceDataKeys.surveys]: [],
  [ReferenceDataKeys.allSpecialties]: [],
  [ReferenceDataKeys.measurementValidationRules]: null,
  [ReferenceDataKeys.vitalsValidationRanges]: null,
  [ReferenceDataKeys.consultantInsuranceTypes]: [],
};

export function referenceDataReducer(
  state = initialState,
  action: ReferenceDataAction,
): ReferenceDataState {
  switch (action.type) {
    case ReferenceDataActionTypes.LoadReferenceDataActionType: {
      return { ...state, loading: true };
    }

    case ReferenceDataActionTypes.LoadReferenceDataActionSuccessType: {
      return {
        ...state,
        loading: false,
        error: null,
        [action.referenceDataKey]: action.payload,
      };
    }

    case ReferenceDataActionTypes.LoadReferenceDataActionErrorType: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        [action.referenceDataKey]: initialState[action.referenceDataKey],
      };
    }

    default: {
      return { ...state };
    }
  }
}
