import { Injectable } from '@angular/core';
import {
  createFeatureSelector,
  createSelector,
  select,
  Store,
} from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs/operators';

import { arrayLengthIsEqual, filterTruthy } from '@app/utils';

import { FeatureFlag } from '../shared/feature-flag.type';
import { FeatureFlagState, featureFlagStatePath } from './feature-flag.reducer';

export const selectFeatureFlagState = createFeatureSelector<FeatureFlagState>(
  featureFlagStatePath,
);

export const selectFeatureFlags = createSelector(
  selectFeatureFlagState,
  (state: FeatureFlagState): FeatureFlag[] => state && state.entity,
);

export const selectFeatureFlagEnabled = createSelector(
  selectFeatureFlags,
  (flags: FeatureFlag[], name: FeatureFlag) => flags && flags.includes(name),
);

@Injectable()
export class FeatureFlagSelectors {
  constructor(private store: Store<FeatureFlagState>) {}

  get featureFlags() {
    return this.store.pipe(
      select(selectFeatureFlags),
      filterTruthy(),
      distinctUntilChanged(arrayLengthIsEqual),
    );
  }

  featureEnabled(name: FeatureFlag) {
    return this.store.pipe(select(selectFeatureFlagEnabled, name));
  }
}
