import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import {
  PopoverComponent,
  PopoverRef,
  PopoverService,
} from '@app/shared/components/popover';

@Component({
  selector: 'omg-ml-suggestion',
  templateUrl: './ml-suggestion.component.html',
  styleUrls: ['./ml-suggestion.component.scss'],
})
export class MlSuggestionComponent {
  @Input() text: string;
  @Input() subtext: string;
  @Input() buttonText: string;
  @Input() buttonVariant = 'primary';
  @Input() hideCloseButton = false;

  @Output() accept = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() tooltipOpened = new EventEmitter<void>();

  @ViewChild('suggestionInfoIcon')
  suggestionInfo: ElementRef;
  @ViewChild('infoPopover', { static: true }) infoPopover: PopoverComponent;

  private infoPopoverRef: PopoverRef;

  constructor(private popoverService: PopoverService) {}

  showInfoPopover() {
    this.infoPopoverRef = this.popoverService.open({
      ...this.infoPopover,
      origin: this.suggestionInfo,
    });

    this.tooltipOpened.emit();
  }

  closeInfoPopover() {
    this.infoPopoverRef.close();
  }
}
