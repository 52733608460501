import { mapToPatientAllergyEntity } from '@app/modules/allergies/shared/allergies-utils';
import { formatDosage, parseDosage } from '@app/modules/shared-rx/utils';
import { camelCase, escapeRegExp } from '@app/utils';

import {
  InteractingMedicationResponse,
  MedicationAllergyResponse,
  MedicationDispensableResponse,
  MedicationDrugInteractionResponse,
  MedicationInteractionResponse,
  MedicationPrescriptionItemTemplateResponse,
  MedicationPrescriptionTemplateResponse,
  MedicationRegimenResponse,
  MedicationRouteResponse,
  MedicationRouteSearchResultResponse,
} from './medications-api.type';
import {
  InteractingMedication,
  MedicationAllergy,
  MedicationDispensable,
  MedicationDrugInteraction,
  MedicationInteraction,
  MedicationPrescriptionItemTemplate,
  MedicationPrescriptionTemplate,
  MedicationRegimen,
  MedicationRegimenForm,
  MedicationRoute,
  MedicationRouteSummary,
  newEntityId,
} from './medications.type';
import { PrnResponse } from './prn.type';

// istanbul ignore next
const mapMedicationPrescriptionItemTemplate = (
  mpit: MedicationPrescriptionItemTemplateResponse,
): MedicationPrescriptionItemTemplate => {
  const {
    dose_high: doseHigh,
    dose_low: doseLow,
    duration_days: durationDays,
    instructions_text: instructionsText,
    medication_dispensable_id: medicationDispensableId,
    id,
    medication_frequency_interval: medicationFrequencyIntervalResponse,
  } = mpit;

  const medicationFrequencyInterval =
    medicationFrequencyIntervalResponse &&
    camelCase(medicationFrequencyIntervalResponse);

  return {
    doseHigh,
    doseLow,
    durationDays,
    instructionsText,
    medicationDispensableId,
    id,
    medicationFrequencyInterval,
  };
};

// istanbul ignore next
export const mapMedicationPrescriptionTemplate = (
  mpt: MedicationPrescriptionTemplateResponse,
): MedicationPrescriptionTemplate => {
  const {
    id,
    medication_prescription_item_template: medicationPrescriptionItemTemplateResponse = {},
  } = mpt;

  const medicationPrescriptionItemTemplate = mapMedicationPrescriptionItemTemplate(
    medicationPrescriptionItemTemplateResponse,
  );

  const medicationPrn = mpt.medication_prn
    ? camelCase(mpt.medication_prn)
    : null;

  return { id, medicationPrescriptionItemTemplate, medicationPrn };
};

// istanbul ignore next
export const mapMedicationDispensableToEntity = (
  dispensable: MedicationDispensableResponse,
) => {
  const { dosing_unit_of_measure: dosingUnitOfMeasure = {} } = dispensable;

  const mappedDosingUnitOfMeasure = {
    desc: dosingUnitOfMeasure.desc,
    descDiff: dosingUnitOfMeasure.desc_diff,
    descPlural: dosingUnitOfMeasure.desc_plural,
  };
  const { medication_clinical_route: clinicalRoute = {} } = dispensable;
  const mappedClinicalRoute = {
    id: clinicalRoute.id,
    description: clinicalRoute.clinical_description,
    layDescription: clinicalRoute.lay_description,
  };

  const entity = {
    id: dispensable.id,
    deaCode: dispensable.dea_code,
    dosingUnitOfMeasure: mappedDosingUnitOfMeasure,
    clinicalRoute: mappedClinicalRoute,
    restrictedControlledSubstance: dispensable.restricted_controlled_substance,
    description: dispensable.text_desc,
    maxDurationDays: dispensable.max_duration_days,
    maxDurationDaysErrorMessage: dispensable.max_duration_days_error_message,
  };

  return entity;
};

// istanbul ignore next
export const mapMedicationDispensablesToEntity = (
  dispensables: MedicationDispensableResponse[],
): MedicationDispensable[] =>
  dispensables.map(mapMedicationDispensableToEntity);

// istanbul ignore next
export const mapMedicationRegimenToEntity = (
  regimen: MedicationRegimenResponse,
): MedicationRegimen => {
  const {
    medication_prescription_template: medicationPrescriptionTemplateResponse = {},
  } = regimen;

  const medicationPrescriptionTemplate = mapMedicationPrescriptionTemplate(
    medicationPrescriptionTemplateResponse,
  );

  const entity = {
    id: regimen.id,
    isActive: regimen.is_active,
    isCustom: regimen.is_custom,
    description: regimen.text_desc,
    medicationPrescriptionTemplate,
    medicationRouteId: regimen.medication_route_id,
    dose: formatDosage(medicationPrescriptionTemplate),
  };

  return entity;
};

// istanbul ignore next
export const mapMedicationRegimensToEntity = (
  regimens: MedicationRegimenResponse[],
): MedicationRegimen[] => regimens.map(mapMedicationRegimenToEntity);

// istanbul ignore next
const formatMedicationRouteSearchName = (
  response: MedicationRouteSearchResultResponse,
  query: string,
): string => {
  const escapedQuery = escapeRegExp(query);
  const re = new RegExp(`(\\d|\\b){1}(${escapedQuery})`, 'i');

  const firstTagMatch = () => {
    if (response.tags && response.tags.length > 0) {
      return response.tags.find(tag => re.test(tag));
    }
    return false;
  };

  if (re.test(response.name)) {
    return response.name;
  } else if (firstTagMatch()) {
    return `${response.name} (${firstTagMatch()})`;
  }
  return response.name;
};

// istanbul ignore next
const mapToNameWithGenericSynonym = (
  response: MedicationRouteSearchResultResponse,
  query: string,
) =>
  /**
   *  It's important in this case (based off observed existing logic)
   *  to map the generic synonym alongside the name
   *  so that it will be picked up in the search results.
   *
   *  @example: within the medicationRouteSearch I can search for 'ase'
   *            and it should pop up 'Saphris (black cherry) asenapine sublingual,
   *            asenapine sublingual being the generic name, but if the generic name
   *            is not included the dropdown is not populated as there is no match
   *            within 'Saphris (black cherry)' for the query, 'ase'.
   */
  response.generic_synonyms && response.generic_synonyms.length > 0
    ? `${response.name} ${response.generic_synonyms[0]}`
    : formatMedicationRouteSearchName(response, query);

// istanbul ignore next
export const mapMedicationRouteSearchResult = (
  response: MedicationRouteSearchResultResponse,
  query: string,
): MedicationRouteSummary => ({
  id: response.id,
  nameWithGenericSynonyms: mapToNameWithGenericSynonym(response, query),
  name: formatMedicationRouteSearchName(response, query),
  isGeneric: response.is_generic,
  additionalEmphasizedText: response.generic_synonyms
    ? response.generic_synonyms[0]
    : null,
});

// istanbul ignore next
export const mapMedicationRouteResponseToEntity = (
  response: MedicationRouteResponse,
): MedicationRoute => ({
  id: response.id,
  name: response.name,
  isActive: response.is_active || null,
  isCustom: response.is_custom || null,
  isGeneric: response.is_generic || null,
  legacy: response.legacy || null,
  dispensables:
    mapMedicationDispensablesToEntity(response.medication_dispensables) || null,
  regimens: mapMedicationRegimensToEntity(response.medication_regimens) || null,
});

// istanbul ignore next
export const mapMedicationAllergyResponseToEntity = (
  response: MedicationAllergyResponse,
): MedicationAllergy => ({
  name: response.allergy_name,
  patientAllergyId: response.patient_allergy_id,
  severity: response.severity,
  reaction: response.reaction || null,
  comment: response.comment || null,
  crossSensitivityDescription: response.xsense_desc || null,
  interactionSeverity: response.interaction_severity,
  patientAllergy: mapToPatientAllergyEntity(response.patient_allergy),
});

// istanbul ignore next
export const mapMedicationAllergyResponseToEntities = (
  response: MedicationAllergyResponse[],
): MedicationAllergy[] => response.map(mapMedicationAllergyResponseToEntity);

// istanbul ignore next
const mapMedicationInteractionReponseToEntities = (
  response: MedicationInteractionResponse[],
): MedicationInteraction[] =>
  response.map(data => ({
    title: data.interaction_title,
    message: data.interaction_msg,
    severityLevelMessage: data.severity_level_msg,
  }));

// istanbul ignore next
const mapInteractingMedicationResponseToEntity = (
  data: InteractingMedicationResponse,
) => ({
  interactingRoutedMedId: data.routed_med_id,
  routedMedName: data.routed_med_name,
  interactingRoutedMed: data.interacting_routed_med,
  interactionCount: data.interaction_count,
  interactions:
    data.interactions &&
    mapMedicationInteractionReponseToEntities(data.interactions),
});

// istanbul ignore next
const mapInteractingMedicationsReponse = (
  response: InteractingMedicationResponse[],
): InteractingMedication[] =>
  response.map(mapInteractingMedicationResponseToEntity);

// istanbul ignore next
export const mapMedicationDrugInteractionResponseToEntity = (
  response: MedicationDrugInteractionResponse,
): MedicationDrugInteraction => ({
  severityLevel: response.severity_level,
  severityLevelTitle: response.severity_level_title,
  interactingMeds:
    response.interacting_meds &&
    mapInteractingMedicationsReponse(response.interacting_meds),
});

// istanbul ignore next
export const mapMedicationDrugInteractionResponseToEntities = (
  response: MedicationDrugInteractionResponse[],
): MedicationDrugInteraction[] =>
  response.map(mapMedicationDrugInteractionResponseToEntity);

/**
 * Form Payloads
 */

/* istanbul ignore next */
export const mapFormToMedicationPrn = (
  data: MedicationRegimenForm,
): PrnResponse => {
  const medicationPrn = {
    ...(data.prnId !== newEntityId
      ? { id: data.prnId, desc: data.prnDescription }
      : { desc: data.prnDescription, is_active: false }),
  };

  return medicationPrn;
};

/* istanbul ignore next */
export const mapFormToMedicationRegimen = (
  data: MedicationRegimenForm,
): MedicationRegimenResponse => {
  const dosage = parseDosage(data.dose);

  return {
    ...(!data.isCustomRegimen ? { id: data.medicationRegimenId } : {}),
    text_desc: data.isCustomRegimen ? data.regimenTextDescription : null,
    medication_route_id: data.medicationRouteId,
    is_custom: data.isCustomRegimen,
    medication_prescription_template_attributes: {
      ...(!data.isCustomRegimen ? { id: data.prescriptionTemplateId } : {}),
      medication_prescription_item_template_attributes: {
        ...(!data.isCustomRegimen
          ? { id: data.prescriptionTemplateItemId }
          : {}),
        dose_low: dosage.doseLow,
        dose_high: dosage.doseHigh,
        medication_dispensable_id: data.dispensableId,
        duration_days: data.durationDays,
        instructions_text: data.useInstructionsText
          ? data.instructionsText
          : null,
        medication_frequency_interval_id: data.frequencyIntervalId,
      },
    },
  };
};

/* istanbul ignore next */
export const mapFormToSaveMedicationRegimenPayload = (
  data: MedicationRegimenForm,
  renewal?: boolean,
) => {
  const medicationPrn = data.usePrn && mapFormToMedicationPrn(data);
  const regimen = mapFormToMedicationRegimen(data);
  return {
    ...(medicationPrn ? { medication_prn: medicationPrn } : {}),
    medication_regimen: regimen,
    renewal,
  };
};

export const mapMedicationRegimenToMedication = (
  regimen: MedicationRegimen,
) => {
  const template = regimen.medicationPrescriptionTemplate;
  const itemTemplate = template && template.medicationPrescriptionItemTemplate;
  const prn = (template && template.medicationPrn) || {
    id: null,
    desc: null,
  };

  return {
    medicationRegimenId: regimen && regimen.id,
    prescriptionTemplateId: template && template.id,
    prescriptionTemplateItemId: itemTemplate && itemTemplate.id,
    prnId: prn.id,
    prnDescription: prn.desc,
  };
};
