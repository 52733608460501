import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { ChangeRx } from '@app/features/change-rx/shared/change-rx.type';
import { Renewal } from '@app/features/renewals/shared/renewals.type';
import {
  MedicationActions,
  MedicationSelectors,
} from '@app/modules/medications/store';
import { ReferenceDataSelectors } from '@app/modules/reference-data/store/reference-data.selectors';
import { FocusService } from '@app/shared/directives/focus/focus.service';

import { RegimenForm } from './regimen-form';

@Injectable()
export class RegimenFormService {
  constructor(
    private referenceDataSelectors: ReferenceDataSelectors,
    private medicationActions: MedicationActions,
    private medicationSelectors: MedicationSelectors,
    private focusService: FocusService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  buildForm(medication: Renewal | ChangeRx | any, unsubscribe$: Subject<void>) {
    return new RegimenForm(
      this.referenceDataSelectors,
      this.medicationActions,
      this.medicationSelectors,
      medication.medicationRoute,
      medication.medicationRegimen,
      this.focusService,
      unsubscribe$,
      this.launchDarklyService,
    );
  }
}
