import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core';
import { PatientAllergy } from '@app/modules/allergies/shared/allergies.type';
import { HealthBackground } from '@app/modules/health-background/shared/health-background.type';
import { HealthGoalsSectionLinkPayload } from '@app/modules/health-maintenance/shared/health-maintenance.type';
import { MedicationsSectionLinkPayload } from '@app/modules/medications/shared';
import { Problem } from '@app/modules/problems/shared/problems.type';
import { VaccinationsSectionLinkPayload } from '@app/modules/vaccinations/shared/vaccinations.type';

import { SummaryUpdateData } from './../shared/summaries-update.type';
import { Summary, SummaryAddendum } from '../shared/summaries.type';
import { SummariesState } from './summaries.reducer';

export enum SummaryActionTypes {
  START_APPOINTMENT_FROM_NOTE = '[Summaries] Start Appointment From Note',
  START_APPOINTMENT_FROM_NOTE_SUCCESS = '[Summaries] Start Appointment From Note Success',
  START_APPOINTMENT_FROM_NOTE_ERROR = '[Summaries] Start Appointment From Note Error',
  REFRESH_NOTE = '[Summaries] Refresh Note',
  LOAD_SUMMARY = '[Summaries] Load Summary',
  LOAD_SUMMARY_SUCCESS = '[Summaries] Load Summary Success',
  LOAD_SUMMARY_ERROR = '[Summaries] Load Summary Error',
  SAVE_SUMMARY = '[Summaries] Save Summary',
  SAVE_SUMMARY_SUCCESS = '[Summaries] Save Summary Success',
  SAVE_SUMMARY_ERROR = '[Summaries] Save Summary Error',
  UPDATE_SUMMARY = '[Summaries] Update Summary',
  UPDATE_SUMMARY_SUCCESS = '[Summaries] Update Summary Success',
  UPDATE_SUMMARY_ERROR = '[Summaries] Update Summary Error',
  SIGN_SUMMARY = '[Summaries] Sign Summary',
  SIGN_SUMMARY_SUCCESS = '[Summaries] Sign Summary Success',
  SIGN_SUMMARY_ERROR = '[Summaries] Sign Summary Error',
  DELETE_SUMMARY = '[Summaries] Delete Summary',
  DELETE_SUMMARY_SUCCESS = '[Summaries] Delete Summary Success',
  DELETE_SUMMARY_ERROR = '[Summaries] Delete Summary Error',
  REDACT_SUMMARY = '[Summaries] Redact Summary',
  REDACT_SUMMARY_SUCCESS = '[Summaries] Redact Summary Success',
  REDACT_SUMMARY_ERROR = '[Summaries] Redact Summary Error',
  LINK_PATIENT_ALLERGIES = '[Summaries] Link Patient Allergies',
  LINK_PATIENT_ALLERGIES_SUCCESS = '[Summaries] Link Patient Allergies Success',
  LINK_PATIENT_ALLERGIES_ERROR = '[Summaries] Link Patient Allergies Error',
  LINK_PROBLEMS = '[Summaries] Link Problems',
  LINK_PROBLEMS_SUCCESS = '[Summaries] Link Problems Success',
  LINK_FAMILY_HEALTH_HISTORY = '[Summaries] Link Family Health History',
  LINK_FAMILY_HEALTH_HISTORY_SUCCESS = '[Summaries] Link Family Health History Success',
  LINK_FAMILY_HEALTH_HISTORY_ERROR = '[Summaries] Link Family Health History Error',
  LINK_PROBLEMS_ERROR = '[Summaries] Link Problems Error',
  LINK_HEALTH_GOALS = '[Summaries] Link Health Goals',
  LINK_HEALTH_GOALS_SUCCESS = '[Summaries] Link Health Goals Success',
  LINK_HEALTH_GOALS_ERROR = '[Summaries] Link Health Goals Error',
  LINK_HEALTH_BACKGROUND = '[Summaries] Link Health Background',
  LINK_HEALTH_BACKGROUND_SUCCESS = '[Summaries] Link Health Background Success',
  LINK_HEALTH_BACKGROUND_ERROR = '[Summaries] Link Health Background Error',
  LINK_MEDICATIONS = '[Summaries] Link Medications',
  LINK_MEDICATIONS_SUCCESS = '[Summaries] Link Medications Success',
  LINK_MEDICATIONS_ERROR = '[Summaries] Link Medications Error',
  LINK_VACCINATIONS = '[Summaries] Link Vaccinations',
  LINK_VACCINATIONS_SUCCESS = '[Summaries] Link Vaccinations Success',
  LINK_VACCINATIONS_ERROR = '[Summaries] Link Vaccinations Error',
  LINK_ASSESSED_PROBLEMS = '[Summaries] Link Assessed Problems',
  LINK_ASSESSED_PROBLEMS_SUCCESS = '[Summaries] Link Assessed Problems Success',
  LINK_ASSESSED_PROBLEMS_ERROR = '[Summaries] Link Assessed Problems Error',
  UNLINK = '[Summaries] Unlink Section',
  UNLINK_SUCCESS = '[Summaries] Unlink Section Success',
  UNLINK_ERROR = '[Summaries] Unlink Section Error',
  UPDATE_SUMMARY_PROVIDER_RECOMMENDATION_ATTACHMENTS = '[Summaries] Update Summary Provider Recommendation Attachments',
  SET_CURRENT_SUMMARY_ID = '[Summaries] Set Current Summary Id',
  CLOSE_WORKSPACE_ITEM = '[Summaries] Close workspace item',
  SAVE_ADDENDUM = '[Summaries] Save Addendum',
  SAVE_ADDENDUM_SUCCESS = '[Summaries] Save Addendum Success',
  SAVE_ADDENDUM_ERROR = '[Summaries] Save Addendum Error',
  UPDATE_TOTAL_COMMENT_COUNT = '[Summaries] Update total comment count',
  DELETE_SERVICE_TICKET_ITEM = '[Summaries] Delete Service Ticket Item',
  DELETE_SERVICE_TICKET_ITEM_SUCCESS = '[Summaries] Delete Service Ticket Item Success',
  DELETE_SERVICE_TICKET_ITEM_ERROR = '[Summaries] Delete Service Ticket Item Error',
}

interface LoadSummaryPayload {
  patientId: number;
  summaryId: number;
}

interface SaveSummaryPayload {
  patientId: number;
  noteTypeId: number;
}

interface UpdateSummaryPayload {
  patientId: number;
  summaryId: number;
  data: SummaryUpdateData;
}

interface UpdateSummaryProviderRecommendationAttachmentsPayload {
  patientId: number;
  summaryId: number;
  data: Partial<SummaryUpdateData>;
}

interface SignSummaryPayload {
  patientId: number;
  summaryId: number;
  summary: Summary;
}

interface DeleteSummaryPayload {
  patientId: number;
  summaryId: number;
}

interface RedactSummaryPayload {
  patientId: number;
  summaryId: number;
}

interface LinkPatientAllergiesPayload {
  patientId: number;
  patientAllergies: PatientAllergy[];
}

interface LinkProblemsPayload {
  patientId: number;
  problems: Problem[];
}

/** The event key is used to tell unsigned-summary-form.component.js
 * which assessed problem linking function to use. It should be
 * deprecated once summaries is downgraded.
 */
interface LinkAssessedProblemsPayload {
  patientId: number;
  problemId: number;
  eventKey: string;
  historyId: number;
  hasAssessedProblem: boolean;
}

interface LinkFamilyHealthHistoryPayload {
  patientId: number;
  addFamilyHealthHistoryToNote: boolean;
}

interface LinkHealthGoalsPayload {
  patientId: number;
  healthGoals: HealthGoalsSectionLinkPayload;
}

interface LinkHealthBackgroundPayload {
  patientId: number;
  healthBackground: HealthBackground;
}

interface LinkMedicationsPayload {
  patientId: number;
  medications: MedicationsSectionLinkPayload;
}

interface LinkVaccinationsPayload {
  patientId: number;
  vaccinations: VaccinationsSectionLinkPayload;
}

interface UnlinkPayload {
  patientId: number;
  type:
    | 'Problems'
    | 'Allergies'
    | 'Medications'
    | 'Vaccines'
    | 'Health-Background'
    | 'Assessment-Plan'
    | 'Health-Maintenance';
  extraFields?: any;
}

type AddendumContent = string;

type SummaryErrorPayload = any;

interface DeleteServiceTicketItemPayload {
  serviceTicketItemId: number;
  summary: Summary;
}

type DeleteServiceTicketItemErrorPayload = any;

export class LoadSummary implements FsaAction<LoadSummaryPayload> {
  readonly type = SummaryActionTypes.LOAD_SUMMARY;

  constructor(public payload: LoadSummaryPayload) {}
}

export class LoadSummarySuccess implements FsaAction<Summary> {
  readonly type = SummaryActionTypes.LOAD_SUMMARY_SUCCESS;
  error = false;

  constructor(public payload: Summary) {}
}

export class LoadSummaryError implements FsaAction<SummaryErrorPayload> {
  readonly type = SummaryActionTypes.LOAD_SUMMARY_ERROR;
  error = true;

  constructor(public payload: SummaryErrorPayload) {}
}

export class SaveSummary implements FsaAction<SaveSummaryPayload> {
  readonly type = SummaryActionTypes.SAVE_SUMMARY;

  constructor(public payload: SaveSummaryPayload) {}
}

export class SaveSummarySuccess implements FsaAction<Summary> {
  readonly type = SummaryActionTypes.SAVE_SUMMARY_SUCCESS;
  error = false;

  constructor(public payload: Summary) {}
}

export class SaveSummaryError implements FsaAction<SummaryErrorPayload> {
  readonly type = SummaryActionTypes.SAVE_SUMMARY_ERROR;
  error = true;

  constructor(public payload: SummaryErrorPayload) {}
}

export class UpdateSummary implements FsaAction<Update<UpdateSummaryPayload>> {
  readonly type = SummaryActionTypes.UPDATE_SUMMARY;

  constructor(public payload: Update<UpdateSummaryPayload>) {}
}

export class UpdateSummarySuccess implements FsaAction<Update<Summary>> {
  readonly type = SummaryActionTypes.UPDATE_SUMMARY_SUCCESS;
  error = false;

  constructor(public payload: Update<Summary>) {}
}

export class UpdateSummaryProviderRecommendationAttachments
  implements
    FsaAction<Update<UpdateSummaryProviderRecommendationAttachmentsPayload>> {
  readonly type =
    SummaryActionTypes.UPDATE_SUMMARY_PROVIDER_RECOMMENDATION_ATTACHMENTS;
  error = false;

  constructor(
    public payload: Update<
      UpdateSummaryProviderRecommendationAttachmentsPayload
    >,
  ) {}
}

export class UpdateTotalSummaryCommentCount
  implements FsaAction<Update<Summary>> {
  readonly type = SummaryActionTypes.UPDATE_TOTAL_COMMENT_COUNT;
  error = false;

  constructor(public payload: Update<Summary>) {}
}

export class UpdateSummaryError implements FsaAction<SummaryErrorPayload> {
  readonly type = SummaryActionTypes.UPDATE_SUMMARY_ERROR;
  error = true;

  constructor(public payload: SummaryErrorPayload) {}
}

export class SignSummary implements FsaAction<Update<SignSummaryPayload>> {
  readonly type = SummaryActionTypes.SIGN_SUMMARY;

  constructor(public payload: Update<SignSummaryPayload>) {}
}

export class SignSummarySuccess implements FsaAction<Update<Summary>> {
  readonly type = SummaryActionTypes.SIGN_SUMMARY_SUCCESS;
  error = false;

  constructor(public payload: Update<Summary>) {}
}

export class SignSummaryError implements FsaAction<SummaryErrorPayload> {
  readonly type = SummaryActionTypes.SIGN_SUMMARY_ERROR;
  error = true;

  constructor(public payload: SummaryErrorPayload) {}
}

export class DeleteSummary implements FsaAction<DeleteSummaryPayload> {
  readonly type = SummaryActionTypes.DELETE_SUMMARY;

  constructor(public payload: DeleteSummaryPayload) {}
}

export class DeleteSummarySuccess implements FsaAction<Summary> {
  readonly type = SummaryActionTypes.DELETE_SUMMARY_SUCCESS;
  error = false;

  constructor(public payload: Summary) {}
}

export class DeleteSummaryError implements FsaAction<SummaryErrorPayload> {
  readonly type = SummaryActionTypes.DELETE_SUMMARY_ERROR;
  error = true;

  constructor(public payload: SummaryErrorPayload) {}
}

export class RedactSummary implements FsaAction<Update<RedactSummaryPayload>> {
  readonly type = SummaryActionTypes.REDACT_SUMMARY;

  constructor(public payload: Update<RedactSummaryPayload>) {}
}

export class RedactSummarySuccess implements FsaAction<Update<Summary>> {
  readonly type = SummaryActionTypes.REDACT_SUMMARY_SUCCESS;
  error = false;

  constructor(public payload: Update<Summary>) {}
}

export class RedactSummaryError implements FsaAction<SummaryErrorPayload> {
  readonly type = SummaryActionTypes.REDACT_SUMMARY_ERROR;
  error = true;

  constructor(public payload: SummaryErrorPayload) {}
}

export class LinkPatientAllergies
  implements FsaAction<LinkPatientAllergiesPayload> {
  readonly type = SummaryActionTypes.LINK_PATIENT_ALLERGIES;

  constructor(public payload: LinkPatientAllergiesPayload) {}
}

export class LinkPatientAllergiesSuccess implements FsaAction<Summary> {
  readonly type = SummaryActionTypes.LINK_PATIENT_ALLERGIES_SUCCESS;
  error = false;

  constructor(public payload: Summary) {}
}

export class LinkPatientAllergiesError
  implements FsaAction<SummaryErrorPayload> {
  readonly type = SummaryActionTypes.LINK_PATIENT_ALLERGIES_ERROR;
  error = true;

  constructor(public payload: SummaryErrorPayload) {}
}

export class LinkProblems implements FsaAction<LinkProblemsPayload> {
  readonly type = SummaryActionTypes.LINK_PROBLEMS;

  constructor(public payload: LinkProblemsPayload) {}
}

export class LinkProblemsSuccess implements FsaAction<Summary> {
  readonly type = SummaryActionTypes.LINK_PROBLEMS_SUCCESS;
  error = false;

  constructor(public payload: Summary) {}
}

export class LinkProblemsError implements FsaAction<SummaryErrorPayload> {
  readonly type = SummaryActionTypes.LINK_PROBLEMS_ERROR;
  error = true;

  constructor(public payload: SummaryErrorPayload) {}
}

export class LinkAssessedProblems
  implements FsaAction<LinkAssessedProblemsPayload> {
  readonly type = SummaryActionTypes.LINK_ASSESSED_PROBLEMS;

  constructor(public payload: LinkAssessedProblemsPayload) {}
}

export class LinkAssessedProblemsSuccess implements FsaAction<Summary> {
  readonly type = SummaryActionTypes.LINK_ASSESSED_PROBLEMS_SUCCESS;

  constructor(public payload: Summary) {}
}

export class LinkAssessedProblemsError
  implements FsaAction<SummaryErrorPayload> {
  readonly type = SummaryActionTypes.LINK_ASSESSED_PROBLEMS_ERROR;

  constructor(public payload: SummaryErrorPayload) {}
}

export class LinkFamilyHealthHistory
  implements FsaAction<LinkFamilyHealthHistoryPayload> {
  readonly type = SummaryActionTypes.LINK_FAMILY_HEALTH_HISTORY;

  constructor(public payload: LinkFamilyHealthHistoryPayload) {}
}

export class LinkFamilyHealthHistorySuccess implements FsaAction<Summary> {
  readonly type = SummaryActionTypes.LINK_FAMILY_HEALTH_HISTORY_SUCCESS;
  error = false;

  constructor(public payload: Summary) {}
}

export class LinkFamilyHealthHistoryError
  implements FsaAction<SummaryErrorPayload> {
  readonly type = SummaryActionTypes.LINK_FAMILY_HEALTH_HISTORY_ERROR;
  error = true;

  constructor(public payload: SummaryErrorPayload) {}
}

export class LinkHealthGoals implements FsaAction<LinkHealthGoalsPayload> {
  readonly type = SummaryActionTypes.LINK_HEALTH_GOALS;

  constructor(public payload: LinkHealthGoalsPayload) {}
}

export class LinkHealthGoalsSuccess implements FsaAction<Summary> {
  readonly type = SummaryActionTypes.LINK_HEALTH_GOALS_SUCCESS;
  error = false;

  constructor(public payload: Summary) {}
}

export class LinkHealthGoalsError implements FsaAction<SummaryErrorPayload> {
  readonly type = SummaryActionTypes.LINK_HEALTH_GOALS_ERROR;
  error = true;

  constructor(public payload: SummaryErrorPayload) {}
}

export class LinkHealthBackground
  implements FsaAction<LinkHealthBackgroundPayload> {
  readonly type = SummaryActionTypes.LINK_HEALTH_BACKGROUND;

  constructor(public payload: LinkHealthBackgroundPayload) {}
}

export class LinkHealthBackgroundSuccess implements FsaAction<Summary> {
  readonly type = SummaryActionTypes.LINK_HEALTH_BACKGROUND_SUCCESS;
  error = false;

  constructor(public payload: Summary) {}
}

export class LinkHealthBackgroundError
  implements FsaAction<SummaryErrorPayload> {
  readonly type = SummaryActionTypes.LINK_HEALTH_BACKGROUND_ERROR;
  error = true;

  constructor(public payload: SummaryErrorPayload) {}
}

export class LinkMedications implements FsaAction<LinkMedicationsPayload> {
  readonly type = SummaryActionTypes.LINK_MEDICATIONS;

  constructor(public payload: LinkMedicationsPayload) {}
}

export class LinkMedicationsSuccess implements FsaAction<Summary> {
  readonly type = SummaryActionTypes.LINK_MEDICATIONS_SUCCESS;
  error = false;

  constructor(public payload: Summary) {}
}

export class LinkMedicationsError implements FsaAction<SummaryErrorPayload> {
  readonly type = SummaryActionTypes.LINK_MEDICATIONS_ERROR;
  error = true;

  constructor(public payload: SummaryErrorPayload) {}
}

export class LinkVaccinations implements FsaAction<LinkVaccinationsPayload> {
  readonly type = SummaryActionTypes.LINK_VACCINATIONS;

  constructor(public payload: LinkVaccinationsPayload) {}
}

export class LinkVaccinationsSuccess implements FsaAction<Summary> {
  readonly type = SummaryActionTypes.LINK_VACCINATIONS_SUCCESS;
  error = false;

  constructor(public payload: Summary) {}
}

export class LinkVaccinationsError implements FsaAction<SummaryErrorPayload> {
  readonly type = SummaryActionTypes.LINK_VACCINATIONS_ERROR;
  error = true;

  constructor(public payload: SummaryErrorPayload) {}
}

export class Unlink implements FsaAction<UnlinkPayload> {
  readonly type = SummaryActionTypes.UNLINK;

  constructor(public payload: UnlinkPayload) {
    switch (payload.type) {
      case 'Problems': {
        payload.extraFields = {
          ...payload.extraFields,
        };

        break;
      }
      case 'Allergies': {
        payload.extraFields = {
          ...payload.extraFields,
          patient_allergy_history_ids: [],
          no_known_allergies: false,
        };

        break;
      }
      case 'Medications': {
        payload.extraFields = {
          no_medications: false,
          patient_medication_regimen_ids: [],
        };

        break;
      }
      case 'Vaccines': {
        payload.extraFields = {
          ...payload.extraFields,
          vaccine_history_ids: [],
        };

        break;
      }
      case 'Health-Background': {
        payload.extraFields = {
          ...payload.extraFields,
          health_background_history_id: null,
        };

        break;
      }
      case 'Health-Maintenance': {
        payload.extraFields = {
          ...payload.extraFields,
          health_goal_history_ids: [],
          health_goal_screening_history_ids: [],
          health_maintenance_note_history_id: null,
        };

        break;
      }
    }
  }
}

export class UnlinkSuccess implements FsaAction<Summary> {
  readonly type = SummaryActionTypes.UNLINK_SUCCESS;

  constructor(public payload: Summary) {}
}

export class UnlinkError implements FsaAction<SummaryErrorPayload> {
  readonly type = SummaryActionTypes.UNLINK_ERROR;

  constructor(public payload: SummaryErrorPayload) {}
}

export class SetCurrentSummaryId implements FsaAction<number> {
  readonly type = SummaryActionTypes.SET_CURRENT_SUMMARY_ID;

  constructor(public payload: number) {}
}

export class CloseWorkspaceItem implements FsaAction<void> {
  readonly type = SummaryActionTypes.CLOSE_WORKSPACE_ITEM;
}

export class SaveAddendum implements FsaAction<AddendumContent> {
  readonly type = SummaryActionTypes.SAVE_ADDENDUM;

  constructor(public payload: AddendumContent) {}
}

export class SaveAddendumSuccess implements FsaAction<SummaryAddendum> {
  readonly type = SummaryActionTypes.SAVE_ADDENDUM_SUCCESS;

  constructor(public payload: SummaryAddendum) {}
}

export class SaveAddendumError implements FsaAction<SummaryErrorPayload> {
  readonly type = SummaryActionTypes.SAVE_ADDENDUM_ERROR;

  constructor(public payload: SummaryErrorPayload) {}
}

export class DeleteServiceTicketItem
  implements FsaAction<DeleteServiceTicketItemPayload> {
  readonly type = SummaryActionTypes.DELETE_SERVICE_TICKET_ITEM;

  constructor(public payload: DeleteServiceTicketItemPayload) {}
}

export class DeleteServiceTicketItemSuccess
  implements FsaAction<DeleteServiceTicketItemPayload> {
  readonly type = SummaryActionTypes.DELETE_SERVICE_TICKET_ITEM_SUCCESS;

  constructor(public payload: DeleteServiceTicketItemPayload) {}
}

export class DeleteServiceTicketItemError
  implements FsaAction<DeleteServiceTicketItemErrorPayload> {
  readonly type = SummaryActionTypes.DELETE_SERVICE_TICKET_ITEM_ERROR;

  constructor(public payload: DeleteServiceTicketItemErrorPayload) {}
}

interface StartVisitFromNotePayload {
  appointmentId: number;
  event: string;
}

export class StartVisitFromNote
  implements FsaAction<StartVisitFromNotePayload> {
  readonly type = SummaryActionTypes.START_APPOINTMENT_FROM_NOTE;

  constructor(public payload: StartVisitFromNotePayload) {}
}

type StartVisitFromNoteErrorPayload = any;

export class StartVisitFromNoteSuccess implements FsaAction<void> {
  readonly type = SummaryActionTypes.START_APPOINTMENT_FROM_NOTE_SUCCESS;
  error = false;
}

export class StartVisitFromNoteError
  implements FsaAction<StartVisitFromNoteErrorPayload> {
  readonly type = SummaryActionTypes.START_APPOINTMENT_FROM_NOTE_ERROR;
  error = true;

  constructor(public payload: StartVisitFromNoteErrorPayload) {}
}

export class RefreshNote implements FsaAction<void> {
  readonly type = SummaryActionTypes.REFRESH_NOTE;
}

export type SummaryAction =
  | CloseWorkspaceItem
  | DeleteSummary
  | DeleteSummaryError
  | DeleteSummarySuccess
  | LinkAssessedProblems
  | LinkAssessedProblemsError
  | LinkAssessedProblemsSuccess
  | LinkFamilyHealthHistory
  | LinkFamilyHealthHistoryError
  | LinkFamilyHealthHistorySuccess
  | LinkHealthBackground
  | LinkHealthBackgroundError
  | LinkHealthBackgroundSuccess
  | LinkHealthGoals
  | LinkHealthGoalsError
  | LinkHealthGoalsSuccess
  | LinkMedications
  | LinkMedicationsError
  | LinkMedicationsSuccess
  | LinkPatientAllergies
  | LinkPatientAllergiesError
  | LinkPatientAllergiesSuccess
  | LinkProblems
  | LinkProblemsError
  | LinkProblemsSuccess
  | LinkVaccinations
  | LinkVaccinationsError
  | LinkVaccinationsSuccess
  | LoadSummary
  | LoadSummaryError
  | LoadSummarySuccess
  | RedactSummary
  | RedactSummarySuccess
  | RedactSummaryError
  | SaveAddendum
  | SaveAddendumError
  | SaveAddendumSuccess
  | SaveSummary
  | SaveSummaryError
  | SaveSummarySuccess
  | SetCurrentSummaryId
  | SignSummary
  | SignSummaryError
  | SignSummarySuccess
  | Unlink
  | UnlinkError
  | UnlinkSuccess
  | UpdateSummary
  | UpdateSummaryError
  | UpdateSummarySuccess
  | UpdateSummaryProviderRecommendationAttachments
  | UpdateTotalSummaryCommentCount
  | DeleteServiceTicketItem
  | DeleteServiceTicketItemSuccess
  | DeleteServiceTicketItemError
  | StartVisitFromNote
  | StartVisitFromNoteSuccess
  | StartVisitFromNoteError
  | RefreshNote;

@Injectable({
  providedIn: 'root',
})
export class SummariesActions {
  constructor(private store: Store<SummariesState>) {}

  loadSummary(payload: LoadSummaryPayload) {
    this.store.dispatch(new LoadSummary(payload));
  }

  updateSummarySuccess(payload: Summary) {
    this.store.dispatch(
      new UpdateSummarySuccess({ id: payload.id, changes: { ...payload } }),
    );
  }

  updateTotalCommentCount(payload: Summary) {
    this.store.dispatch(
      new UpdateTotalSummaryCommentCount({
        id: payload.id,
        changes: payload,
      }),
    );
  }

  saveSummary(payload: SaveSummaryPayload) {
    this.store.dispatch(new SaveSummary(payload));
  }

  updateSummary(payload: Update<UpdateSummaryPayload>) {
    this.store.dispatch(new UpdateSummary(payload));
  }

  signSummary(payload: Update<SignSummaryPayload>) {
    this.store.dispatch(new SignSummary(payload));
  }

  deleteSummary(payload: DeleteSummaryPayload) {
    this.store.dispatch(new DeleteSummary(payload));
  }

  redactSummary(payload: Update<RedactSummaryPayload>) {
    this.store.dispatch(new RedactSummary(payload));
  }

  linkPatientAllergies(payload: LinkPatientAllergiesPayload) {
    this.store.dispatch(new LinkPatientAllergies(payload));
  }

  linkProblems(payload: LinkProblemsPayload) {
    this.store.dispatch(new LinkProblems(payload));
  }

  linkAssessedProblems(payload: LinkAssessedProblemsPayload) {
    this.store.dispatch(new LinkAssessedProblems(payload));
  }

  linkFamilyHealthHistory(payload: LinkFamilyHealthHistoryPayload) {
    this.store.dispatch(new LinkFamilyHealthHistory(payload));
  }

  linkHealthGoals(payload: LinkHealthGoalsPayload) {
    this.store.dispatch(new LinkHealthGoals(payload));
  }

  linkHealthBackground(payload: LinkHealthBackgroundPayload) {
    this.store.dispatch(new LinkHealthBackground(payload));
  }

  linkMedications(payload: LinkMedicationsPayload) {
    this.store.dispatch(new LinkMedications(payload));
  }

  linkVaccinations(payload: LinkVaccinationsPayload) {
    this.store.dispatch(new LinkVaccinations(payload));
  }

  unlink(payload: UnlinkPayload) {
    this.store.dispatch(new Unlink(payload));
  }

  setCurrentSummaryId(payload: number) {
    this.store.dispatch(new SetCurrentSummaryId(payload));
  }

  closeWorkspaceItem() {
    this.store.dispatch(new CloseWorkspaceItem());
  }

  saveAddendum(payload: AddendumContent) {
    this.store.dispatch(new SaveAddendum(payload));
  }

  deleteServiceTicketItem(payload: DeleteServiceTicketItemPayload) {
    this.store.dispatch(new DeleteServiceTicketItem(payload));
  }

  startVisitFromNote(payload: StartVisitFromNotePayload) {
    this.store.dispatch(new StartVisitFromNote(payload));
  }

  refreshNote() {
    this.store.dispatch(new RefreshNote());
  }

  updateSummaryProviderRecommendationAttachments(
    payload: Update<UpdateSummaryProviderRecommendationAttachmentsPayload>,
  ) {
    this.store.dispatch(
      new UpdateSummaryProviderRecommendationAttachments(payload),
    );
  }
}
