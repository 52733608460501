import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';

import { ErrorHandlerService } from '../../errors/error-handler.service';
import { GET_PROFILE } from '../../profile/store/profile.actions';
import { FeatureFlagApiService } from '../shared/feature-flag-api.service';
import { FeatureFlag } from '../shared/feature-flag.type';
import {
  GET_FEATURE_FLAGS,
  GetFeatureFlagsError,
  GetFeatureFlagsSuccess,
} from './feature-flag.actions';
import { FeatureFlagState } from './feature-flag.reducer';
import { selectFeatureFlags } from './feature-flag.selectors';

@Injectable()
export class FeatureFlagEffects {
  constructor(
    private action$: Actions,
    private store: Store<FeatureFlagState>,
    private featureFlagApi: FeatureFlagApiService,
    private errorHandler: ErrorHandlerService,
  ) {}

  /** Effect also listens to getProfile to handle the initial load of feature flags. */

  getFeatureFlags$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(GET_PROFILE, GET_FEATURE_FLAGS),
      withLatestFrom(this.store.pipe(select(selectFeatureFlags))),
      /** Check if the features flags has been loaded before. */
      filter(([action, featureFlags]) => !featureFlags),
      mergeMap(() =>
        this.featureFlagApi.get().pipe(
          map(
            (response: FeatureFlag[]) => new GetFeatureFlagsSuccess(response),
          ),
          catchError((error: any) =>
            of(
              new GetFeatureFlagsError(
                this.errorHandler.handleErrorSafe(error),
              ),
            ),
          ),
        ),
      ),
    ),
  );
}
