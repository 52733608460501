import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
export interface ButtonGroupOption {
  label: string;
  value: any;
}
@Component({
  selector: 'omg-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
})
export class ButtonGroupComponent {
  @Input() options: ButtonGroupOption[];
  @Input() label: string;
  @Input() buttonGroupControl: UntypedFormControl;
  @Input() groupName: string;

  get radioGroupName(): string {
    return this.groupName || 'buttonGroupOption';
  }

  @ViewChild('buttonGroupOption')
  buttonGroupOption: ElementRef;

  focus() {
    this.buttonGroupOption.nativeElement.focus();
  }
}
