<div class="omg-dialog-container"
     style="width: 560px"
     data-cy="help-request-modal"
     omgTheme>
  <div omgDialogTitleDirective>
    <h5 class="help-request-confirmation-title">Create HelpOut Request</h5>
    <button omgButton
            variant="none"
            class="om-icon icon-close clickable"
            (click)="close()"></button>
  </div>
  <div omgDialogContentDirective
       om-layout="vertical"
       om-layout-gutter
       class="padding-normal">
    <p *ngIf="!isLinkedToNote">
      Your request below will be submitted to find an available qualified team member to assist with this patient.
      <a [href]="helpOutWikiLink"
         target="_blank"
         data-cy="help-out-wiki-link">Learn More</a>
    </p>

    <form [formGroup]="form.controls">
      <div om-layout="vertical"
           om-layout-gutter>
        <label class="plain-text">
          What kind of help request is this?
          <omg-dropdown formControlName="helpflowType"
                        [options]="form.helpFlowTypes"
                        [autoDisplayFirst]="false"
                        data-cy="helpflow-type-dropdown"
                        [flex]="true"></omg-dropdown>
        </label>
        <label>
          Patient's Current Location
          <omg-dropdown formControlName="licensingBodyId"
                        [options]="form.usStates"
                        [autoDisplayFirst]="false"
                        data-cy="licensing-body-dropdown"
                        [flex]="true"></omg-dropdown>
        </label>
        <div om-layout
             om-layout-gutter>
          <label om-layout="vertical"
                 om-flex="50"
                 om-layout-gutter>
            Contact Person
            <input omgInputText
                   formControlName="callbackName" />
          </label>
          <label om-layout="vertical"
                 om-flex="50"
                 om-layout-gutter>
            Contact Number
            <input omgInputText
                   placeholder="555-555-5555"
                   formControlName="callbackNumber" />
          </label>
        </div>
        <div class="alert"
             *ngIf="form.controls.get('callbackNumber').errors && form.controls.get('callbackNumber').dirty">Phone
          number is invalid.</div>

        <omg-checkbox label="Is the patient/caller with you now?"
                      data-cy="caller-on-the-line-checkbox"
                      [plainLabel]="true"
                      formControlName="callerOnTheLine"></omg-checkbox>

        <ng-container *ngIf="isLinkedToNote; else detailsField">
          <p>
            The following request will be generated and submitted to find an available VMT provider to assist with this
            patient.
            <a [href]="helpOutWikiLink"
               target="_blank"
               data-cy="help-out-wiki-link">Learn More</a>
          </p>

          <div class="help-request-preview padding-normal"
               om-layout="vertical"
               om-layout-gutter>
            <ng-container *ngIf="hasRequiredNoteDetails; else missingNoteDetails">
              <p om-layout="vertical"
                 om-layout-gutter>
                <strong>Note Details</strong>
                Chart: {{ requestOptions.basicPatientDetails }}
                <span>Subject: {{ requestOptions.summary.subject }}</span>
                <span *ngIf="!!requestOptions.summary.subjective"
                      [innerHTML]="requestOptions.summary.subjective"></span>
              </p>
            </ng-container>
          </div>
        </ng-container>

        <ng-template #detailsField>
          <label class="plain-text">
            Add some details about this request
            <textarea omgTextarea
                      autosize
                      rows="3"
                      om-layout-fill
                      data-cy="help-out-detail-text-area"
                      formControlName="details"
                      [maxlength]="form.maxDetailsLength"></textarea>
            <span class="caption"
                  data-cy="help-out-detail-remaining-characters">
              {{ form.controls.get('details').value | omgRemainingCharacters: form.maxDetailsLength }} characters
              remaining
            </span>
          </label>

          <div class="marked">
            Be sure to include:
            <ul>
              <li>
                The name of the caller (if not the patient), their callback number and who they represent (e.g. Aetna,
                Imaging facility)
              </li>
              <li>What needs to happen (e.g. "Sign this order" or "report stat lab results")</li>
            </ul>
          </div>
        </ng-template>
      </div>
    </form>
  </div>
  <div omgDialogActionsDirective>
    <button omgButton
            variant="primary"
            data-cy="submit-help-request-button"
            [disabled]="form.controls.invalid || saving"
            (click)="getVMTHelp()">
      Request Help
    </button>
  </div>
</div>
<ng-template #missingNoteDetails>
  <p>Please provide details in the Note Title and Subject fields of the note to generate a HelpOut.</p>
</ng-template>
