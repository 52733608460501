import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AnalyticsActions, PatientSelectors } from '@app/core';
import { AnalyticsEvent } from '@app/core/analytics/shared/analytics.type';
import { summariesPath } from '@app/features/workspace/shared/workspace-utils';
import { filterTruthy } from '@app/utils';

@Component({
  selector: 'omg-patient-timeline-posts',
  templateUrl: './patient-timeline-posts.component.html',
  styleUrls: ['./patient-timeline-posts.component.scss'],
})
export class PatientTimelinePostsComponent implements OnInit {
  postId: Observable<number>;
  patientId: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private patientSelectors: PatientSelectors,
    private analytics: AnalyticsActions,
  ) {}

  ngOnInit() {
    this.postId = this.route.paramMap.pipe(
      map((params: ParamMap) => +params.get('id')),
    );

    this.patientSelectors.patientId
      .pipe(filterTruthy(), take(1))
      .subscribe(id => (this.patientId = id));
  }

  onClosePost() {
    this.trackClosePost();
    this.router.navigateByUrl(summariesPath(this.patientId, null, 'new'));
  }

  private trackClosePost(): void {
    this.postId.subscribe(postId => {
      this.analytics.trackEvent(AnalyticsEvent.ClinicalTimelineObjectClosed, {
        workflow: 'Charting',
        component: 'Clinical Timeline',
        activeCharting: true,
        subcomponent: 'Message',
        noteId: null,
        orderId: null,
        summaryId: null,
        patientTimelinePostId: postId,
        method: 'Push',
      });
    });
  }
}
