import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';

import { AnalyticsActions } from '@app/core';
import { AnalyticsEvent } from '@app/core/analytics/shared/analytics.type';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { ProcedureSuggestionActions } from '@app/features/procedure-suggestion/store/procedure-suggestion.actions';
import { OrderTypes } from '@app/modules/orders/shared/order.type';
import { get } from '@app/utils';

import { ProcedureSuggestion } from '../../shared/procedure-suggestion.type';
import { buildMlProcedureBillingSuggestionEventPayload } from '../../shared/procedure-suggestion.utils';

type State = 'suggesting' | 'dismissing' | 'dismissed' | 'ordered' | 'feedback';

@Component({
  selector: 'omg-procedure-suggestion',
  templateUrl: './procedure-suggestion.component.html',
  styleUrls: ['./procedure-suggestion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcedureSuggestionComponent implements OnInit {
  @Input() procedureSuggestion: ProcedureSuggestion;
  @Input() patientId: number;

  feedbackCategories: String[];
  procedureInteractionEnabled$: Observable<boolean>;

  get state(): State {
    if (this.procedureSuggestion.dismissing) {
      return 'dismissing';
    } else if (this.procedureSuggestion.feedback) {
      return 'feedback';
    } else if (this.procedureSuggestion.isDismissed) {
      return 'dismissed';
    } else if (this.procedureSuggestion.itemId) {
      return 'ordered';
    }

    return 'suggesting';
  }

  get name() {
    return get(
      'procedureType[0].clinicalDescription',
      this.procedureSuggestion,
    );
  }

  constructor(
    public procedureSuggestionActions: ProcedureSuggestionActions,
    public analyticsActions: AnalyticsActions,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    if (this.state === 'suggesting') {
      this.trackAnalytics(AnalyticsEvent.MlProcedureBillingSuggestionActivated);
    }

    this.feedbackCategories = [
      'Not indicated',
      'Declined',
      'Performed outside OM',
      'Previously completed at OM',
      'Other',
    ];

    this.procedureInteractionEnabled$ = this.launchDarklyService.variation$(
      FeatureFlagNames.billingProcedureInteractions,
      false,
    );
  }

  createProcedureOrder() {
    const problemHistory = this.procedureSuggestion.problemHistory;
    this.procedureSuggestionActions.createProcedureOrder(
      this.procedureSuggestion,
      {
        type: OrderTypes.ProcedureOrder,
        procedureTypeId: this.procedureSuggestion.procedureType[0].id,
        indication: {
          problemTypeId: problemHistory.problemType.id,
          problemCodeLocationId: problemHistory.problemCodeLocation
            ? problemHistory.problemCodeLocation.id
            : undefined,
        },
        performNow: true,
      },
    );
    this.trackAnalytics(AnalyticsEvent.MlProcedureBillingSuggestionRecorded);
  }

  createProcedureInteraction() {
    this.procedureSuggestionActions.createProcedureInteraction(
      this.patientId,
      this.procedureSuggestion.problemHistory.problemId,
      this.procedureSuggestion,
    );
    this.trackAnalytics(AnalyticsEvent.MlProcedureBillingSuggestionRecorded);
  }

  createProcedure() {
    this.procedureInteractionEnabled$.subscribe(procedureInteractionEnabled => {
      if (procedureInteractionEnabled) {
        this.createProcedureInteraction();
      } else {
        this.createProcedureOrder();
      }
    });
  }

  dismiss() {
    this.procedureSuggestionActions.dismiss(this.procedureSuggestion.id);
    this.trackAnalytics(AnalyticsEvent.MlProcedureBillingSuggestionDismissed);
  }

  giveFeedback() {
    this.procedureSuggestionActions.giveFeedback(this.procedureSuggestion.id);
  }

  closeFeedback() {
    this.procedureSuggestionActions.closeFeedback(this.procedureSuggestion.id);
  }

  private trackAnalytics(eventType: AnalyticsEvent) {
    this.analyticsActions.trackEvent(
      eventType,
      buildMlProcedureBillingSuggestionEventPayload(this.procedureSuggestion),
    );
  }
}
