<omg-ml-suggestion *ngIf="!isCaptureGenerated || !isCaptureVerified"
                   [text]="mlSuggestionText"
                   [hideCloseButton]="true">
  <div class="procedure-capture-explainability"
       *ngIf="!isCaptureVerified">
    <div class="procedure-capture-explainability-header">How procedure importing works <span
            class="om-icon clickable icon-dismiss -gray procedure-capture-explainability-dismiss"></span></div>
    <div class="procedure-capture-explainability-body">Procedure imports use machine learning to scan incoming
      faxes and documents for historical procedure data and saves a draft to the Clinical Data Platform.
      Read more on <a class="procedure-capture-explainability-link"
         href="https://sites.google.com/onemedical.com/electronic-service-tickets/faqs/suggestion-faq"
         target="_blank">our
        wiki</a>.
    </div>
  </div>
</omg-ml-suggestion>
<label *ngIf="procedureCdpSnomedCodesFF"
       om-layout="vertical"
       class="procedure-edit-tree-select-label">
  Procedure
</label>
<p-treeSelect *ngIf="procedureCdpSnomedCodesFF"
              [options]="procedureCdpSnomedCodesTreeNodes"
              [(ngModel)]="selectedNode"
              placeholder="Select Item"
              (onNodeSelect)="setProcedureCodeFormControlFromTreeSelect($event.node.code)"
              class="procedure-edit-tree-select"></p-treeSelect>

<form *ngIf="form"
      [formGroup]="form.controls"
      (submit)="saveHistoricalProcedureCapture(form.controls.value)"
      om-layout="vertical"
      om-layout-gutter-large
      class="procedure-capture-form padding-half-vertical"
      [class.form-attempted-submit]="formAttemptedSubmit">
  <label *ngIf="!procedureCdpSnomedCodesFF"
         om-layout="vertical">
    Procedure
    <omg-auto-complete om-flex="70"
                       [clearable]="true"
                       formControlName="procedureCode"
                       [items]="procedureTypeOptions"
                       bindLabel="label"
                       bindValue="value"
                       trackByKey="value"
                       placeholder="Select Procedure">
    </omg-auto-complete>
  </label>
  <div class="alert procedure-capture-form-alert"
       *ngIf="controlHasError('procedureCode')">
    Please choose a procedure
  </div>

  <label om-layout="vertical"
         om-flex="25">
    Procedure date
    <omg-date-picker class="procedure-date-picker"
                     [maxDate]="currentDate"
                     placeholder="Select Date"
                     formControlName="procedureDate"
                     om-flex>
    </omg-date-picker>
    <div class="alert procedure-capture-form-alert"
         *ngIf="controlHasError('procedureDate')">
      Please choose a date
    </div>
  </label>

  <label>
    Results
    <input om-layout="vertical"
           omgInputText
           type="text"
           name="result"
           formControlName="procedureResult">
  </label>

  <div om-layout
       om-layout-gutter>
    <button type="submit"
            omgButton
            variant="secondary"
            [disabled]="!isCaptureGenerated">
      Save procedure
    </button>
    <button omgButton
            variant="flat"
            (click)="collapseForm.emit()">
      Cancel
    </button>
  </div>
</form>
