import { Component, Input, OnInit } from '@angular/core';

export enum Size {
  small = 'small',
  large = 'large',
}

@Component({
  selector: 'omg-profile-image-bubble',
  templateUrl: './profile-image-bubble.component.html',
  styleUrls: ['./profile-image-bubble.component.scss'],
})
export class ProfileImageBubbleComponent implements OnInit {
  @Input() initials: string;
  @Input() url: string;
  @Input() overlay = false;
  @Input() colorCombo: number;
  @Input() size = Size.small;

  private readonly COLOR_COMBINATIONS = 5;
  private readonly DEFAULT_COLOR_COMBINATION = 1;

  resolvableImage: string;

  ngOnInit() {
    this.resolvableImage = this.url;
    if (this.colorCombo) {
      this.colorCombo = (this.colorCombo % this.COLOR_COMBINATIONS) + 1;
    } else {
      this.colorCombo = this.DEFAULT_COLOR_COMBINATION;
    }
  }

  fallbackToInitials() {
    this.resolvableImage = null;
  }
}
