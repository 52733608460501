import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import {
  VitalsByType,
  vitalTypes,
} from '@app/modules/vitals-data/shared/vitals-data.type';
import { filterTruthy } from '@app/utils';

@Component({
  selector: 'omg-summary-growth-chart',
  templateUrl: './summary-growth-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryGrowthChartComponent implements OnInit {
  @Input() vitals: VitalsByType;
  @Input() forInfant: boolean;

  weight: number;
  height: number;
  weightForLength: number;
  headCircumference: number;
  BMI: number;

  weightForLengthDisplay$: Observable<boolean>;

  constructor(private launchDarklyService: LaunchDarklyService) {}

  ngOnInit(): void {
    this.weight = this.vitals[vitalTypes.weightPercentile]?.value;
    this.height = this.vitals[vitalTypes.heightPercentile]?.value;
    this.headCircumference = this.vitals[
      vitalTypes.headCircumferencePercentile
    ]?.value;
    this.BMI = this.vitals[vitalTypes.bmiPercentile]?.value;

    this.weightForLengthDisplay$ = this.launchDarklyService.variation$(
      FeatureFlagNames.pedsVitalsWeightForLengthPercentilesDisplay,
      false,
    );

    this.weightForLengthDisplay$
      .pipe(take(1), filterTruthy())
      .subscribe(flagEnabled => {
        if (flagEnabled) {
          this.weightForLength = this.vitals[
            vitalTypes.weightForLengthPercentile
          ]?.value;
        }
      });
  }
}
