<div class="user-info-popover"
     data-cy="user-info-popover"
     *ngIf="userInfo$ | async as user; else loading">
  <div om-layout="vertical"
       om-layout-gutter
       om-layout-align="start"
       class="user-info-card">
    <div om-layout
         om-layout-align="left top">
      <omg-profile-image-bubble class="user-info-avatar"
                                [url]="user.profileImageUrl"
                                [initials]="user.initials"
                                [colorCombo]="user.id"
                                [size]="large"></omg-profile-image-bubble>
      <div om-layout="vertical">
        <div om-layout
             om-layout-align="start center">
          <div class="user-info-name">{{ user.firstName }} {{ user.lastName }}</div>
          <div class="user-info-role"
               data-cy="user-info-role">{{ roleName }}</div>
        </div>
        <div>{{ user.suffix }}</div>
        <div *ngIf="user.workSchedule?.returningOn"
             om-layout
             om-layout-align="start center"
             data-cy="returning-on">
          <div class="status-bubble"></div>
          Out of office until {{ user.workSchedule.returningOn | omgDate: '4y' }}
        </div>
      </div>
    </div>
    <div om-layout="vertical"
         class="user-info-location">
      <div>{{ user.serviceArea?.name }}</div>
      <div>{{ user.defaultOffice?.name }}</div>
    </div>
    <omg-collapse *ngIf="user.workSchedule"
                  (expandedChange)="toggleChevron()"
                  data-cy="work-schedule">
      <div omgCollapseToggle
           om-layout
           om-layout-align="start center">
        <i *ngIf="expanded"
           class="chevron pi pi-chevron-right"></i>
        <i *ngIf="!expanded"
           class="chevron pi pi-chevron-down"></i>
        <div class="trigger">Working days</div>
      </div>
      <omg-expanded>
        <div om-layout>
          <div class="day"
               [class.-out]="user.workSchedule.sundaysOff">S</div>
          <div class="day"
               [class.-out]="user.workSchedule.mondaysOff">M</div>
          <div class="day"
               [class.-out]="user.workSchedule.tuesdaysOff">T</div>
          <div class="day"
               [class.-out]="user.workSchedule.wednesdaysOff">W</div>
          <div class="day"
               [class.-out]="user.workSchedule.thursdaysOff">T</div>
          <div class="day"
               [class.-out]="user.workSchedule.fridaysOff">F</div>
          <div class="day"
               [class.-out]="user.workSchedule.saturdaysOff">S</div>
        </div>
      </omg-expanded>
    </omg-collapse>
  </div>
  <hr class="solid" />
  <div om-layout="horizontal"
       om-layout-align="space-between center"
       class="bottom-bar">
    <a [href]="scheduleUrl"
       data-cy="schedule-link"
       target="_blank"
       title="Open schedule">
      <svg width="24"
           height="24"
           viewBox="0 0 24 24"
           fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M20 3H19V1H17V3H7V1H5V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM20 21H4V8H20V21Z"
              fill="#0358B2" />
      </svg>
    </a>
    <a class="text-link"
       [href]="directoryUrl"
       data-cy="directory-link"
       target="_blank">View Directory</a>
  </div>
</div>

<ng-template #loading>
  <omg-feature-loading-spinner></omg-feature-loading-spinner>
</ng-template>
