import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProfileSelectors } from '@app/core';

import { ChangeRx } from '../../shared/change-rx.type';

@Component({
  selector: 'omg-change-rx-medication-change-status',
  templateUrl: './change-rx-medication-change-status.component.html',
  styleUrls: ['./change-rx-medication-change-status.component.scss'],
})
export class ChangeRxMedicationChangeStatusComponent implements OnInit {
  @Input() changeRx: ChangeRx;
  @Output() readyToSign = new EventEmitter<boolean>();

  readyToSignControl = new UntypedFormControl();

  isSignedOnBehalfOf$: Observable<boolean>;
  signedOnBehalfOfName: string;

  constructor(private profileSelectors: ProfileSelectors) {}

  ngOnInit(): void {
    this.isSignedOnBehalfOf$ = this.profileSelectors.profileId.pipe(
      map(
        providerId =>
          providerId !== this.changeRx.rxChangeRequest.prescriber.id,
      ),
    );
    this.signedOnBehalfOfName = this.changeRx.rxChangeRequest.prescriber.name;
  }
}
