import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';

import { DatePipe } from '@app/shared/pipes';

const addFunctions = {
  D: addDays,
  W: addWeeks,
  M: addMonths,
  Y: addYears,
};

const subtractFunctions = {
  D: subDays,
  W: subWeeks,
  M: subMonths,
  Y: subYears,
};

// case insensitive D/W/M/Y, followed by optional (+/-),
// followed by a number that does not begin with 0, up to 3 digits
const hotKeyRegex = /^[dDwWmMyY][-+]?[1-9]\d{0,2}$/;

export const isValidShortCut = val => {
  return val.match(hotKeyRegex) !== null;
};

export const getShortCutValue = val => {
  if (!isValidShortCut(val)) {
    return null;
  }

  // '+' is optional for future dates, default to future unless '-' is present
  const funcList = val.indexOf('-') === -1 ? addFunctions : subtractFunctions;
  const fn = funcList[val[0].toUpperCase()];
  const today = new Date();
  const firstDigitIndex = val.search(/\d/);
  return new Date(fn(today, val.slice(firstDigitIndex)));
};

export const getFormattedShortcutValue = (val, formatStr = 'MM/dd/yyyy') => {
  const shorcutValue = getShortCutValue(val);
  return shorcutValue
    ? new DatePipe('en-US').transform(shorcutValue, formatStr)
    : '';
};
