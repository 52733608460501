<div om-layout="vertical"
     om-layout-gutter
     *ngIf="labData$ | ngrxPush as labData">
  <!--Header -->
  <omg-collapse om-layout-gutter
                om-layout="vertical"
                [expanded]="true">
    <div om-layout
         om-layout-gutter>
      <omg-file-link [key]="awsKey"
                     [encode]="false"
                     [bucket]="awsBucket"
                     text="Download PDF">
      </omg-file-link>
      <omg-collapse-toggle>
        <button omgButton
                variant="flat">
          <span omgCollapsed>Expand Lab Header</span>
          <span omgExpanded>Collapse Lab Header</span>
        </button>
      </omg-collapse-toggle>
    </div>

    <div om-layout
         om-layout-align="space-between center"
         omgTheme>
      <strong class="source-display-title">{{labData.labSourceDisplay}}</strong>
      <span class="om-label"
            *ngIf="labData?.status"
            [ngClass]="{'-inactive': labData.status === 'Final', '-warning': labData.status !== 'Final'}">
        {{labData.status}}
      </span>
    </div>
    <div>Received on {{labData.reportedAt | omgDate: '4y'}}</div>
    <div class="banner -warning"
         *ngIf="failedAutoSend">
      <b>Auto-send Failed</b>
      <br />
      This lab report may appear sent but we encountered an issue that caused the auto-send to fail. Please report the
      results to the patient by composing a message. We are working to identify the cause of the issue.
    </div>
    <div *ngIf="successfulAutoSend; else showAutoreportabilityEvaluation">
      <div class="banner -info">
        <b>Auto-Reported to Patient</b>
        {{labData.patientTimelinePost.sentAt | omgDate: '4y'}} at
        {{labData.patientTimelinePost.sentAt | omgDate: '12Time'}}
        <ul class="padding-normal-vertical">
          <li *ngIf="!!labData.omReferenceRanges.length">
            The following One Medical ranges were used to determine auto-reporting to the patient:
            <div class="padding-normal">
              <div class="padding-normal-horizontal"
                   *ngFor="let referenceRange of labData.omReferenceRanges">
                {{ referenceRange }}
              </div>
            </div>
          </li>
          <li *ngIf="reportedWithAbnormals; else normalsOnly">
            These abnormal results have been autoreported to the patient. Additional follow-up may be needed.
          </li>
          <ng-template #normalsOnly>
            <li>This report contains no abnormal or critical results</li>
          </ng-template>
        </ul>
        <div>
          <a [routerLink]="getTimelinePostRoute(labData.patientTimelinePost.id)">View
            Patient Message</a>
        </div>
      </div>
    </div>

    <ng-template #showAutoreportabilityEvaluation>
      <omg-automatic-process-explanation *ngIf="(displayAutoreportabilityRulesEnabled$ | async) && labData.autoReportableChecks"
                                         text="Results not autoreported">
        <omg-lab-autoreportability [autoReportableChecks]="labData.autoReportableChecks">
        </omg-lab-autoreportability>
      </omg-automatic-process-explanation>
    </ng-template>

    <omg-expanded class="padding-normal-vertical">
      <omg-lab-result-header [labData]="labData"
                             class="padding-normal-vertical"></omg-lab-result-header>
    </omg-expanded>
  </omg-collapse>

  <!--Body-->
  <div class="data-table">
    <div *ngFor="let request of labData.observationRequests; let first = first;">
      <div *ngIf="first"
           class="heading">
        <b om-flex>Tests</b>
        <b om-flex="30">Results</b>
        <b om-flex="20">Reference</b>
        <b om-flex="5"></b>
      </div>
      <omg-section-title>
        {{request.reportHeading}} ({{request.results.length}})
      </omg-section-title>
      <div class="row"
           om-layout-align="start center"
           [ngClass]="{'-important': result.abnormal, '-critical': result.critical}"
           *ngFor="let result of request.results">
        <span om-flex
              om-offset="5">
          {{result.name}}
        </span>
        <span om-flex="5">
          <span *ngIf="result.critical"
                title="Critical"
                class="om-icon icon-alert-warning"></span>
          <span *ngIf="result.abnormal"
                title="Abnormal"
                class="om-icon icon-alert-flag"></span>
          <span *ngIf="result.cancelled"
                title="Cancelled"
                class="om-icon icon-alert-cancelled"></span>
        </span>
        <span om-flex="30">{{result.value}}</span>
        <span om-flex="20">
          <span *ngIf="result.unitsIdentifier"
                omgTooltip
                [tooltipText]="result.unitsIdentifier"
                tooltipPosition="top">
            {{result.referencesRange}}
          </span>
          <span *ngIf="!result.unitsIdentifier">{{result.referencesRange}}</span>
        </span>
        <div *ngIf="result.notes"
             om-layout
             om-layout-align="end"
             om-offset="10">
          <pre>{{result.notes}}</pre>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <div *ngFor="let facility of labData.facilities; let index = index"
       om-layout
       om-layout-align="space-between">
    <span om-flex="5">{{index}}</span>
    <span om-flex="10">{{facility.code}}</span>
    <span om-flex>{{facility.name}}
      <br />{{facility.streetAddress}}
      <br />{{facility.csz}}</span>
    <span om-flex="25">{{facility.director}}</span>
  </div>
  <div>{{labData.contact}}</div>
</div>
